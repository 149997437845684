type DocumentType = Record<string, string[]>;

export const documentTypeByCountry: DocumentType = {
  AR: ['L.E / DNI', 'CARNET EXT.', 'PASAPORTE', 'P. NAC.', 'OTROS'],
  CL: ['RUT', 'PASAPORTE'],
  PY: ['RUC', 'PASAPORTE'],
  MX: ['Número de Documento', 'PASAPORTE'],
  US: ['ID Card', 'PASAPORTE'],
  UY: ['RUT', 'PASAPORTE'],
  DF: ['Número de Documento', 'PASAPORTE'],
};
