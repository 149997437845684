export const statisticsES = {
  TITLE: 'Posición Global',
  LIST: 'Listado',
  COMPANIES: 'Sociedades',
  PERSONS: 'Personas',
  EXPIRATIONS: 'Vencimientos',
  VERIFIRED: 'Verificadas',
  NOT_VERIFIRED: 'Sin Verificar',
  IND: 'Fisicas',
  LEGAL: 'Juridicas',
  UP_SHAREHOLDERS: 'Accionistas',
  UP_POWERS: 'Poderes',
  UP_AUTH: 'Autoridades',
  EXP_1: 'Vencido',
  EXP_2: 'Menos de 30 Días',
  EXP_3: 'Entre 30 y 60 Días',
  EXP_4: 'Más de 60 Días',
};

export const statisticsEN = {
  TITLE: 'Detailed Information',
  LIST: 'List',
  COMPANIES: 'Companies',
  PERSONS: 'Persons',
  EXPIRATIONS: 'Expirations',
  VERIFIRED: 'Verified',
  NOT_VERIFIRED: 'Not Verified',
  IND: 'Individuals',
  LEGAL: 'Legal Entities',
  UP_SHAREHOLDERS: 'Shareholders Uploaded',
  UP_POWERS: 'Powers Uploaded',
  UP_AUTH: 'Authorities Uploaded',
  EXP_1: 'Expired',
  EXP_2: 'Less than 30 Days',
  EXP_3: 'Between 30 and 60 Days',
  EXP_4: 'More than 60 Days',
};
