import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CompaniesService } from "../services/companies.service";
import { RestService } from "../services/rest.service";

@Injectable({
  providedIn: "root",
})
export class CountrySettingService {
  private currentCountry_id: number;
  currency_types_id: any;

  constructor(
    private restService: RestService,
    private companyService: CompaniesService
  ) { }

  setCurrentCountryID(countryID) {
    this.currentCountry_id = countryID;
    this.companyService.getTiposMoneda().subscribe((res) => {
      this.currency_types_id = res.find(
        (o) => o.countries_id === countryID
      )?.id;
    });
  }

  getCurrentCountryID(): number {
    return this.currentCountry_id;
  }

  getCurrencyByCountry(): number {
    return this.currency_types_id;
  }

  getCurrentCountryCode(): string {
    return this.getCountryCodeByCountryID(this.currentCountry_id);
  }

  getCountryCodeByCountryID(countryID): string {
    switch (countryID) {
      case 40:
        return "CL";
      case 11:
        return "AR";
      case 178:
        return "PY";
      case 239:
        return "UY";
      case 65:
        return "US";
      case 141:
        return "MX";
      default:
        return "DF";
    }
  }

  searchCountryCode(company_id?): Observable<string> | string {
    if (this.currentCountry_id) {
      return this.getCurrentCountryCode();
    } else {
      return this.getCompanyCountrycode(company_id);
    }
  }

  getCompanyCountrycode(company_id): Observable<string> {
    return this.restService.get_api(`companies/${company_id}`).pipe(
      map((res) => {
        if (res.success) {
          this.setCurrentCountryID(res.result.country_id);
          return this.getCurrentCountryCode();
        } else {
          return null;
        }
      })
    );
  }

  getCompanyType(type) {
    return this.restService.get_api(`companies/type/${type}`).pipe(
      map((res) => {
        if (res.success) {
          return res.result;
        }
      })
    );
  }
}
