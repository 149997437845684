<div *ngIf="colMd!==7" [ngClass]="(hasParentFormGroup) ? 'form-group row' : 'row'" [formGroup]="parentFormGroup">
    <div [ngClass]="'col-md-' + (colMd === 7? 12 : colMd)">
        <label>{{ 'BY_COUNTRY.'+countryCode+'.UBICATION.PROVINCE' | translate }} </label>
        <mat-select class="form-control  " name="province_id" formControlName="province_id"
            (selectionChange)="onChangeProvince(false)" [class.is-invalid]="isControlInvalid('province_id')"
            [class.is-valid]="isControlValid('province_id')">
            <mat-option *ngFor="let element of provinces" [value]="element.key.province_id">
                {{ element.key.province }}
            </mat-option>
        </mat-select>
    </div>

    <div [ngClass]="'col-md-' + (colMd === 7? 12 : colMd)" *ngIf="optionalLocation">
        <mat-checkbox class="mb-3" style="margin-top: 30px; padding-bottom: 10px;" [color]="'primary'"
            [checked]="hasLocation" (change)="onCheckLocation($event)" [labelPosition]="'after'">
            {{ 'BY_COUNTRY.'+countryCode+'.UBICATION.LOCATION' | translate }}
        </mat-checkbox>

    </div>

    <div [ngClass]="'col-md-' + (colMd === 7? 12 : colMd)"
        *ngIf="!optionalLocation || (optionalLocation && hasLocation)" [@fadein]>
        <label>{{ 'BY_COUNTRY.'+countryCode+'.UBICATION.LOCATION' | translate }}</label>
        <input class="form-control  " [formControl]="Locationcontrol"
            [class.is-invalid]="Locationcontrol.touched && Locationcontrol.invalid"
            [class.is-valid]="Locationcontrol.touched && Locationcontrol.valid" [matAutocomplete]="auto">
        <mat-autocomplete (optionSelected)="onLocationChange($event)" #auto="matAutocomplete">
            <mat-option *ngFor="let location of filteredStreets | async" [value]="location">
                {{location.name}}
            </mat-option>
        </mat-autocomplete>
    </div>
    <div [ngClass]="'col-md-' + colMd"
        *ngIf="countryCode !== 'PY' && countryCode !== 'CL' && countryCode !== 'MX' && colMd!==7 && (!optionalLocation || (optionalLocation && hasLocation))"
        [@fadein]>
        <label>{{'BY_COUNTRY.'+countryCode+'.UBICATION.POSTAL_CODE' | translate }}</label>
        <input type="number" class="form-control  " autocomplete="off" name="postal_code" formControlName="postal_code"
            [class.is-invalid]="isControlInvalid('postal_code')" [class.is-valid]="isControlValid('postal_code')" 
            onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 187 && event.keyCode !== 109 && event.keyCode !== 107"/>
    </div>
</div>

<div *ngIf="colMd===7" [ngClass]="(hasParentFormGroup) ? 'form-group row' : 'row'" [formGroup]="parentFormGroup">
    <div [ngClass]="'col-md-12'">
        <label>{{('BY_COUNTRY.'+countryCode+'.UBICATION.PROVINCE' | translate )}}</label>
        <mat-select class="form-control  " name="province_id" formControlName="province_id"
            (selectionChange)="onChangeProvince(false)" [class.is-invalid]="isControlInvalid('province_id')"
            [class.is-valid]="isControlValid('province_id')">
            <mat-option *ngFor="let element of provinces" [value]="element.key.province_id">
                {{ element.key.province }}
            </mat-option>
        </mat-select>
    </div>
</div>

<div *ngIf="colMd===7" [ngClass]="(hasParentFormGroup) ? 'form-group row' : 'row'" [formGroup]="parentFormGroup">
    <div [ngClass]="'col-md-12'">
        <label>{{('BY_COUNTRY.'+countryCode+'.UBICATION.LOCATION' | translate )}}</label>
        <input class="form-control  " [formControl]="Locationcontrol"
            [class.is-invalid]="Locationcontrol.touched && Locationcontrol.invalid"
            [class.is-valid]="Locationcontrol.touched && Locationcontrol.valid" [matAutocomplete]="auto">
        <mat-autocomplete (optionSelected)="onLocationChange($event)" #auto="matAutocomplete">
            <mat-option *ngFor="let location of filteredStreets | async" [value]="location">
                {{location.name}}
            </mat-option>
        </mat-autocomplete>
    </div>

</div>