import { NgModule } from '@angular/core';
import { A11yModule } from '@angular/cdk/a11y';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';


@NgModule({
    exports: [
        A11yModule,
        MatTooltipModule,
        MatTableModule,
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        MatSelectModule
    ]
})
export class CustomMaterialModule { }