import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ExternalAuthGuard } from "./auth/external-auth.guard";
import { Layout } from "gojs";
import { LayoutComponent } from "./pages/layout/layout.component";

export const routes: Routes = [
  {
    path: "",
    canActivate: [ExternalAuthGuard],
    component: LayoutComponent,
    children: [
      {
        path: "companies",
        loadChildren: () =>
          import("src/app/modules/company-list/company-list.module").then(
            (m) => m.CompanyListModule
          ),
      },
      {
        path: "create",
        loadChildren: () =>
          import("src/app/modules/company-create/company-create.module").then(
            (m) => m.CompanyCreateModule
          ),
      },
      {
        path: "company",
        loadChildren: () =>
          import("src/app/modules/company-view/company-view.module").then(
            (m) => m.CompanyViewModule
          ),
      },
      {
        path: "map",
        loadChildren: () =>
          import("src/app/modules/corporate-full-map/corporate-full-map.module").then(
            (m) => m.CorporateFullMapModule
          ),
      },
      {
        path: "library",
        loadChildren: () =>
          import(
            "src/app/modules/document-library/document-library.module"
          ).then((m) => m.DocumentLibraryModule),
      },
      {
        path: "docs",
        loadChildren: () =>
          import("src/app/modules/document-list/document-list.module").then(
            (m) => m.DocumentListModule
          ),
      },
      {
        path: "document",
        loadChildren: () =>
          import("src/app/modules/document-view/document-view.module").then(
            (m) => m.DocumentViewModule
          ),
      },
      {
        path: "users",
        loadChildren: () =>
          import("src/app/modules/users/users.module").then(
            (m) => m.UsersModule
          ),
      },
      {
        path: "logs",
        loadChildren: () =>
          import("src/app/modules/logs/logs.module").then(
            (m) => m.LogsModule
          ),
      },
      {
        path: "individuals",
        loadChildren: () =>
          import("src/app/modules/individuals/individuals.module").then(
            (m) => m.IndividualsModule
          ),
      },
      {
        path: "expirations",
        loadChildren: () =>
          import("src/app/modules/expirations/expirations.module").then(
            (m) => m.ExpirationsModule
          ),
      },
      /* {
        path: 'registral',
        loadChildren: () =>
          import('src/app/modules/registral/registral.module').then(
            (m) => m.RegistralModule
          ),
      },*/
      {
        path: "information",
        loadChildren: () =>
          import(
            "src/app/modules/information-shared/information-shared.module"
          ).then((m) => m.InformationSharedModule),
      },
       {
        path: 'upload',
        loadChildren: () =>
          import('src/app/modules/powers-upload/powers-upload.module').then(
            (m) => m.PowersUploadModule
          ),
      },
      { path: "", redirectTo: "companies", pathMatch: "full" },
      { path: "**", redirectTo: "companies", pathMatch: "full" },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
