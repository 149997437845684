import { NgModule, APP_INITIALIZER, LOCALE_ID } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ClipboardModule } from "ngx-clipboard";
import { TranslateModule } from "@ngx-translate/core";
import { InlineSVGModule } from "ng-inline-svg";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import localeEsAr from "@angular/common/locales/es-AR";
import { registerLocaleData } from "@angular/common";
// Highlight JS
import { HighlightModule, HIGHLIGHT_OPTIONS } from "ngx-highlightjs";
// #fake-end#
import { FileMaterialModule } from "./modules/document-library/material-module";
import { JitCompilerFactory } from "@angular/platform-browser-dynamic";
import { DialogConfirmComponent } from "./modules/shared/dialog/dialog-confirm.component";
import { SharedModule } from "./modules/shared/shared.module";
import { IndividualsModule } from "./modules/individuals/individuals.module";
import { SplashScreenModule } from "./pages/splash-screen/splash-screen.module";
import { ExternalAuthGuard } from "./auth/external-auth.guard";
import { ExternalAuthInterceptor } from "./auth/external-auth-interceptor.service";
import { ExternalAuthService } from "./auth/external-auth.service";
import { LayoutModule } from "./pages/layout/layout.module";

function appInitializer(externalAuthGuard: ExternalAuthGuard) {
  return () => {};
}

export function createJitCompiler() {
  return new JitCompilerFactory().createCompiler();
}
registerLocaleData(localeEsAr, "es-Ar");

@NgModule({
  declarations: [AppComponent, DialogConfirmComponent],
  imports: [
    FileMaterialModule,
    BrowserModule,
    BrowserAnimationsModule,
    SplashScreenModule,
    LayoutModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    HighlightModule,
    ClipboardModule,
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    SharedModule,
    IndividualsModule,
  ],
  providers: [
    ExternalAuthService,
    {
      provide: LOCALE_ID,
      useValue: "es-Ar",
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ExternalAuthInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [ExternalAuthGuard],
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import("highlight.js/lib/core"),
        languages: {
          xml: () => import("highlight.js/lib/languages/xml"),
          typescript: () => import("highlight.js/lib/languages/typescript"),
          scss: () => import("highlight.js/lib/languages/scss"),
          json: () => import("highlight.js/lib/languages/json"),
        },
      },
    },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
