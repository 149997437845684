import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { langOption, langOptions } from './models/lang-options';
import { profileOptionsADMIN } from './models/profile-options';
import { TranslationService } from 'src/app/core/countries-managment/i18n/translation.service';
import { ExternalAuthService } from 'src/app/auth/external-auth.service';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  profileButtons = [];
  languageSelector = langOptions;
  selectedLanguage: langOption = langOptions[1];
  userInfo;
  constructor(
    private router: Router,
    private translationService: TranslationService,
    private auth: ExternalAuthService
  ) {}

  ngOnInit(): void {
    this.userInfo = this.auth.getCurrentUserInfo();
    if (typeof this.userInfo?.role === 'string') {
      if (this.userInfo.role === 'admin') {
        if (!this.userInfo?.enableSwitchFIRM) {
          this.profileButtons = profileOptionsADMIN.filter(
            (e) => e.key !== 'firm'
          );
        } else {
          this.profileButtons = profileOptionsADMIN;
        }
      } else if (this.userInfo?.role === 'sign') {
        this.profileButtons = profileOptionsADMIN.filter(
          (e) => e.key === 'users'
        );
      }
    } else {
      if (this.userInfo?.role?.find((e) => e === 'admin')) {
        if (!this.userInfo?.enableSwitchFIRM) {
          this.profileButtons = profileOptionsADMIN.filter(
            (e) => e.key !== 'firm'
          );
        } else {
          this.profileButtons = profileOptionsADMIN;
        }
      }
      if (this.userInfo?.role?.find((e) => e === 'sign')) {
        this.profileButtons = profileOptionsADMIN.filter(
          (e) => e.key === 'users'
        );
      }
    }

    let storageLang = this.translationService.getSelectedLanguage();
    let storageSelectedLang = this.languageSelector.find(
      (e) => e.lang === storageLang
    );
    this.setLenguageBycountryCode(
      storageSelectedLang ? storageSelectedLang : langOptions[1]
    );
  }

  ngOnDestroy(): void {}

  setLenguageBycountryCode(lang: langOption) {
    this.translationService.setDefaultLanguage(lang.lang);
    this.translationService.setLanguage(lang.lang);
    this.selectedLanguage = lang;
  }

  onClickMenu(key: string) {
    switch (key) {
      case 'users':
        this.router.navigate([key]);
        break;
      case 'logs':
        this.router.navigate([key]);
        break;
      case 'portal':
        this.auth.goToPortal();
        break;
      case 'firm':
        this.auth.switchFirm();
        break;
    }
  }

  logOut() {
    this.auth.logout(8);
  }
}
