<form [formGroup]="addressFormGroup" *ngIf="addressFormGroup" >
    <div class="form-group row">
        <div class="col-md-12">
            <label>{{ 'BY_COUNTRY.'+countryCode+'.INDIVIDUALS.STREET' | translate }}</label>
            <input type="text" class="form-control form-control-lg" autocomplete="off" name="address"
                formControlName="address" />
        </div>
    </div>
    <div class="form-group row">
        <div class="col-md-4">
            <label>{{ 'BY_COUNTRY.'+countryCode+'.INDIVIDUALS.ADDRESS_NUMBER' | translate }}</label>
            <input type="text" class="form-control form-control-lg" autocomplete="off" name="address_number"
                formControlName="address_number" />
        </div>
        <div class="col-md-4">
            <label>{{ 'BY_COUNTRY.'+countryCode+'.INDIVIDUALS.FLOOR' | translate }}</label>
            <input type="text" class="form-control form-control-lg" autocomplete="off" name="floor"
                formControlName="floor" />
        </div>
        <div class="col-md-4" *ngIf="countryCode !== 'PY' && countryCode !== 'UY'">
            <label>{{ 'BY_COUNTRY.AR.INDIVIDUALS.DEPARTMENT' | translate }}</label>
            <input type="text" class="form-control form-control-lg" autocomplete="off" name="department"
                formControlName="department" />
        </div>
    </div>
</form>

<app-locations [colMd]="7" [countryCode]="countryCode" #location [parentFormGroup]="addressFormGroup"></app-locations>