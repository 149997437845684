import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { SelectionModel } from "@angular/cdk/collections";
import { Fadein } from "src/app/animations";

export const columnTypes = {
  regular: "regular",
  link: "link",
  date: "date",
  iconLink: "icon-link",
  iconAction: "icon-action",
  iconMultiAction: "icon-multi-action",
  toggleSvgIcon: "toggle-svg-icon",
  regularWMulticolorIcons: "regular-w-multicolor-icon",
  selection: "selection",
  combination: "combination",
  detailed: "detailed",
  list: "list",
  checkbox: "checkbox",
  percentage: "percentage",
  checkboxReadonly: "checkbox-readonly",
};

export interface TableColumn {
  caption: string; // title for the column itself
  field: string; // field of the payload that the component is gonna read over
  type:
    | "regular"
    | "link"
    | "date"
    | "icon-link"
    | "icon-action"
    | "icon-multi-action"
    | "toggle-svg-icon"
    | "regular-w-multicolor-icon"
    | "selection"
    | "combination"
    | "detailed"
    | "checkbox"
    | "checkbox-readonly"
    | "percentage"
    | "list"; // types of columns
  linkToRedirectTo?: string; // valid for type link and icon-link
  uppercase?: boolean; // valid for regular type
  fieldOfRedirection?: string;
  fieldOfCombination?: string;
  actionType?: string; // valid for type icon-action and toggle-svg-icon
  actionTitle?: string; // valid for type icon-action abd toggle-svg-icon
  colors?: Object; //valid por regular with multicolor icons
  colorField?: string;
  icon?: string; // valid for icon-link and icon-action  ( material icons name )
  iconColor?: string;
  multiActionIcons?: Action[]; //valid for type icon-multi-action
  largeCol?: boolean;
  svgIcon?: string;
  svgPathOn?: string;
  svgPathOff?: string;
  order?: boolean;
  translate?: boolean;
}

export interface Action {
  actionType?: string;
  actionTitle: string;
  icon?: string;
  link?: string;
  linkDynamic?: string;
  conditional?: boolean;
  fieldOfCondition?: string;
  svgPath?: string;
  fieldOfRedirection?: string;
  svgIcon?: string;
}
export interface TableActionPayload {
  action: string;
  payload: any;
}

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
  animations: [Fadein],
})
export class TableComponent implements OnInit, OnChanges {
  displayedColumns: Array<any>;
  columnTypes = columnTypes;
  allSelected = false;
  expandedElement;
  expanded = null;
  @Input() columns: TableColumn[] = [];
  @Input() dataSource: any = [];
  @Input() largeTable?: boolean = false;
  @Input() loading?: boolean;
  @Input() pageSize?: number = 5;
  @Input() selection?: SelectionModel<any>;
  @Output() actionClicked = new EventEmitter<TableActionPayload>(true);
  @Output() colClicked = new EventEmitter<any>(true);
  columnOrder: string;
  orderDesc = true;
  
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.displayedColumns = this.columns.map(
      (tableColumn: TableColumn) => tableColumn.caption
    );
  }

  setOrderFirst(action){
    this.columnOrder = action;
  } 

  actClicked(action: string, payload) {
    this.actionClicked.emit({ action, payload });
  }

  clClicked(action: string, orderDesc) {
    this.orderDesc = !this.orderDesc;
    this.columnOrder = action;
    this.colClicked.emit({ action, orderDesc });
  }

  showDetail(id) {
    this.expanded = this.expanded ? null : (this.expanded = id);
  }

  handleSelection(element: any) {
    this.selection?.toggle(element);
    this.isAllSelected();
  }

  masterToggle() {
    if (this.allSelected) {
      this.selection.clear();
      this.allSelected = false;
    } else {
      this.dataSource.forEach((row) => this.selection.select(row));
      this.allSelected = true;
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    this.allSelected = numSelected === numRows;
  }
}
