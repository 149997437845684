import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Fadein } from "src/assets/animations/animations";

@Component({
  selector: "app-buttons",
  templateUrl: "./buttons.component.html",
  styleUrls: ["./buttons.component.scss"],
  animations: [Fadein],
})
export class ButtonsComponent {
  @Input() label = "";

  @Input() type:
    | "primary"
    | "signout"
    | "primary-md"
    | "primary-lg"
    | "primary-lg-icon"
    | "primary-icon"
    | "secondary"
    | "secondary-md"
    | "secondary-lg"
    | "secondary-lg-icon"
    | "loading"
    | "delete"
    | "warning" = "primary";
  

  @Input() icon: "add" | "link" | "share" | "" = "";
  @Input() iconPosition: "right" | "left" = "left";
  @Input() loading = false;
  @Input() disable = false;

  @Output() clickEmit: EventEmitter<any> = new EventEmitter();

  emitClick() {
    this.clickEmit.emit(true);
  }
}
