import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SplashScreenService } from './pages/splash-screen/splash-screen.service';
import { TableExtendedService } from './modules/shared/crud-table';
import { TranslationService } from './core/countries-managment/i18n/translation.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  constructor(
    private translationService: TranslationService,
    private splashScreenService: SplashScreenService,
    private router: Router,
    private tableService: TableExtendedService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    // register translations
    this.translationService.loadTranslations();
    this.registerIcons();
  }

  ngOnInit() {
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // clear filtration paginations and others
        this.tableService.setDefaults();
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('page-loaded');
        }, 500);
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  registerIcons() {
    this.matIconRegistry.addSvgIcon(
      'pdf',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/file-explorer-icons/pdf-icon.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'important',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/important.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'doc',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/file-explorer-icons/docs-icon.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'sheet',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/file-explorer-icons/xlsx-icon.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'image',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/file-explorer-icons/image-icon.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'download-doc',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/download.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'upload-doc',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/upload.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'attachment',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/attachment.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'link',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/link.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'link-active',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/link-active.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'brevity_icon_email',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/media/svg/brevity/login-email.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'brevity_icon_password',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/media/svg/brevity/login-password.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'brevity_eye_password',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/media/svg/icons/General/eye-outline.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'brevity_eye_off_password',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/media/svg/icons/General/eye-off-outline.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'edit-l',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/edit-light.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'visibility-i',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/visibility-i.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'active',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/active.svg'
      )
    );
  }
}
