import { Injectable, OnDestroy, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { exhaustMap, map } from 'rxjs/operators';
import { Companies } from 'src/app/core/models/companies.model';
import { ApiResult } from 'src/app/core/models/api-result.model';
import { baseFilter } from 'src/app/core/helpers/http-extenstions';
import { environment } from 'src/environments/environment';
import { RestService } from './rest.service';
import {
  ITableState,
  TableResponseModel,
  TableService,
} from 'src/app/modules/shared/crud-table';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService
  extends TableService<Companies>
  implements OnDestroy
{
  API_URL = `${environment.apiUrl}Companies`;
  constructor(@Inject(HttpClient) http, private restService: RestService) {
    super(http);
  }

  // READ
  find(tableState: ITableState): Observable<TableResponseModel<Companies>> {
    return this.http.get<ApiResult>(this.API_URL).pipe(
      map((response: ApiResult) => {
        const filteredResult = baseFilter(response.result, tableState);
        const result: TableResponseModel<Companies> = {
          items: filteredResult.items,
          total: filteredResult.total,
        };
        return result;
      })
    );
  }

  deleteItems(ids: number[] = []): Observable<any> {
    const tasks$ = [];
    ids.forEach((id) => {
      tasks$.push(this.delete(id));
    });
    return forkJoin(tasks$);
  }

  updateStatusForItems(ids: number[], status: number): Observable<any> {
    return this.http.get<ApiResult>(this.API_URL).pipe(
      map((Companies: ApiResult) => {
        return Companies.result
          .filter((c) => ids.indexOf(c.id) > -1)
          .map((c) => {
            c.fav = status;
            return c;
          });
      }),
      exhaustMap((Companies: Companies[]) => {
        const tasks$ = [];
        Companies.forEach((Company) => {
          tasks$.push(this._update(Company));
        });
        return forkJoin(tasks$);
      })
    );
  }

  updateFavorite(companyId: number): Observable<any> {
    return this.restService.post_to(null, `companies/followers/${companyId}`);
  }

  getLegalPersonsPaginated(
    pageIndex: number,
    pageSize: number,
    companyId: number,
    filter: string = ''
  ): Observable<ApiResult> {
    this.restService.setPath('companies/legal-persons');
    return this.restService.getByParams({
      start: pageIndex * pageSize,
      limit: pageSize,
      companyId: companyId,
      filter: filter.trim(),
    });
  }

  getCompanyType() {
    this.API_URL = `${environment.apiUrl}` + 'wizzard/get_filters';
    return this.http.get<any>(`${this.API_URL}`).pipe(
      map((response: ApiResult) => {
        return response.result;
      })
    );
  }

  getProvinces() {
    this.API_URL = `${environment.apiUrl}` + 'provinces/all';
    return this.http.get<any>(`${this.API_URL}`).pipe(
      map((response: ApiResult) => {
        return response.result;
      })
    );
  }

  getProvincesByCountry(countryId) {
    this.API_URL = `${environment.apiUrl}provinces/all/${countryId}`;
    return this.http.get<any>(`${this.API_URL}`).pipe(
      map((response: ApiResult) => {
        return response.result;
      })
    );
  }

  getLocationsbyProvince(provinceId) {
    if (provinceId) {
      this.API_URL =
        `${environment.apiUrl}` + `locations/province/${provinceId}`;
      return this.http.get<any>(`${this.API_URL}`).pipe(
        map((response: ApiResult) => {
          return response.result;
        })
      );
    }
  }

  getUsers() {
    this.restService.setPath('users');
    return this.restService.getAll();
  }

  getTiposMoneda() {
    this.API_URL = `${environment.apiUrl}` + 'currency_types/list';
    return this.http.get<any>(`${this.API_URL}`).pipe(
      map((response: ApiResult) => {
        return response.result;
      })
    );
  }

  updateCompany(id, data) {
    return this.restService.put_api(`companies/${id}`, data);
  }

  getCompanyStatistics(id) {
    return this.restService.get_api(`statistics/${id}`).pipe(
      map((res) => {
        if (res.success) {
          const result = res.result;
          const colors = ['#e17681', '#f4bc53', '#fff3b0', '#8ed580'];
          const totalEXP = result.expirations.reduce(
            (accumulator, currentValue) => accumulator + currentValue.value,
            0
          );

          return {
            expirationsDataset: result.expirations.map((exp, index) => {
              return {
                label: 'STATS.EXP_' + exp.id,
                value: exp.value,
                color: colors[index],
                total: totalEXP,
              };
            }),
            cardItems: [
              { label: 'STATS.UP_SHAREHOLDERS', value: result.shareholder , icon :'diversity_3'},
              { label: 'STATS.UP_POWERS', value: result.powers , icon :'description'},
              { label: 'STATS.UP_AUTH', value: result.authorities ,icon :'groups'},
            ],
          };
        } else {
          return {
            companiesDataset: [],
            expirationsDataset: [],
            personsDataset: [],
          };
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }
}
