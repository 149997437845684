<div class="col-md-12 attachment">

    <div class="title-attachment">
        {{label | translate}}

        <div *ngIf="currentFile" class="download-buttons" (click)="downloadFile()" #tooltip="matTooltip"
            matTooltip="{{'BUTTONS.DOWNLOAD' | translate}}">
            <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24"
                width="24px" fill="#797882">
                <g>
                    <rect fill="none" height="24" width="24" />
                </g>
                <g>
                    <path
                        d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M17,11l-1.41-1.41L13,12.17V4h-2v8.17L8.41,9.59L7,11l5,5 L17,11z" />
                </g>
            </svg>
        </div>

        <div class="download-buttons" *ngIf="currentFile && !edit" #tooltip="matTooltip" matTooltip="{{'LABELS.EDIT' | translate}}"
            (click)="toggleEdit()">
            <svg _ngcontent-enb-c403="" width="24" height="24" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path _ngcontent-enb-c403="" fill-rule="evenodd" clip-rule="evenodd" d="M6 15.5V18H8.5L15.8733
                10.6267L13.3733
                8.12668L6 15.5Z" fill="#797882"></path>
                <path _ngcontent-enb-c403="" fill-rule="evenodd" clip-rule="evenodd" d="M17.8064
                    8.69335C18.0664 8.43335 18.0664
                    8.01335
                    17.8064 7.75335L16.2464
                    6.19335C15.9864
                    5.93335 15.5664 5.93335 15.3064
                    6.19335L14.0864 7.41335L16.5864
                    9.91335L17.8064 8.69335Z" fill="#797882"></path>
            </svg>
        </div>

        <div class="download-buttons" *ngIf="currentFile && edit" #tooltip="matTooltip"
            matTooltip="{{'BUTTONS.CLOSE' | translate}} edicion de Archivo" (click)="toggleEdit()">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#797882">
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
                </svg>
        </div>
    </div>

    <label *ngIf="edit" for="{{id}}" class="attachment-label">

        <div class="input-attachment">
            <div class="name-attachment">{{file_name}}</div>
            <div class="img-attachment">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#797882">
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path
                        d="M18.5 16H7c-2.21 0-4-1.79-4-4s1.79-4 4-4h12.5c1.38 0 2.5 1.12 2.5 2.5S20.88 13 19.5 13H9c-.55 0-1-.45-1-1s.45-1 1-1h9.5V9.5H9c-1.38 0-2.5 1.12-2.5 2.5s1.12 2.5 2.5 2.5h10.5c2.21 0 4-1.79 4-4s-1.79-4-4-4H7c-3.04 0-5.5 2.46-5.5 5.5s2.46 5.5 5.5 5.5h11.5V16z" />
                </svg>
            </div>
        </div>
    </label>
    <input id="{{id}}" class="attachment" type="file" (change)="upload_attachment($event)" />
</div>