<div class="form-group row" *ngIf="IndividualType === 0">
    <div class="col-md-12" style="padding: 0;margin :0">
        <app-input-file label="{{  labelInput | translate}}" [id]="'document'" [currentFile]="documentFile"
            (emitFile)="fileUploaded($event , 'document')">
        </app-input-file>
    </div>

    <br *ngIf="documentFile">
    <br *ngIf="documentFile">

    <div class="col-md-12" style="padding: 0;margin :0">
        <app-input-file label="{{'LABELS.ADDITIONAL_INFO' | translate}}" [id]="'othersDocuments'"
            [currentFile]="addressFile" (emitFile)="fileUploaded($event , 'others')">
        </app-input-file>
    </div>
</div>

<div class="form-group row" *ngIf="IndividualType === 1">
    <div class="col-md-12" style="padding: 0;margin :0">
        <app-input-file label="{{  'LABELS.INSCRIPTION' | translate}}" [id]="'inscription'"
            [currentFile]="InscriptionFile" (emitFile)="fileUploaded($event , 'inscription')">
        </app-input-file>
    </div>

    <br *ngIf="InscriptionFile">
    <br *ngIf="InscriptionFile">

    <div class="col-md-12" style="padding: 0;margin :0">
        <app-input-file label="{{'LABELS.STATUTE' | translate}}" [id]="'byLaw'" [currentFile]="ByLawFile"
            (emitFile)="fileUploaded($event , 'byLaw')">
        </app-input-file>
    </div>

    <br *ngIf="ByLawFile">
    <br *ngIf="ByLawFile">

    <div class="col-md-12" style="padding: 0;margin :0">
        <app-input-file label="{{'LABELS.LAST_INSCRIPTION' | translate}}" [id]="'lastInscription'"
            [currentFile]="LastInscriptionFile" (emitFile)="fileUploaded($event , 'lastInscription')">
        </app-input-file>
    </div>
</div>