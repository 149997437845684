import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InlineSVGModule } from "ng-inline-svg";
import {
  NgbDropdownModule,
  NgbProgressbarModule,
} from "@ng-bootstrap/ng-bootstrap";
import { MatBadgeModule } from "@angular/material/badge";
import { ScriptsInitComponent } from "./components/scipts-init/scripts-init.component";
import { AsideComponent } from "./components/aside/aside.component";
import { FooterComponent } from "./components/footer/footer.component";
import { LayoutComponent } from "./layout.component";
import { SharedModule } from "src/app/modules/shared/shared.module";
import { TranslationModule } from "src/app/core/countries-managment/i18n/translation.module";
import { MatIconModule } from "@angular/material/icon";
import { NavbarComponent } from "./navbar/navbar.component";
import { MatMenuModule } from "@angular/material/menu";
import { ButtonsModule } from "src/app/components/buttons/buttons.module";
import { RouterModule } from "@angular/router";

@NgModule({
  declarations: [
    LayoutComponent,
    ScriptsInitComponent,
    AsideComponent,
    FooterComponent,
    NavbarComponent,
  ],
  imports: [
    CommonModule,
    TranslationModule,
    InlineSVGModule,
    NgbDropdownModule,
    NgbProgressbarModule,
    MatBadgeModule,
    SharedModule,
    MatIconModule,
    MatMenuModule,
    ButtonsModule,
    RouterModule
  ],
  exports : [LayoutComponent]
})
export class LayoutModule {}
