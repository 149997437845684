<div id="modal-container">
    <div class="text-container">
        <h3>{{data.text | translate}}</h3>
        <p [innerHTML]="data.description"></p>
    </div>

    <div class="actions-container" *ngIf="data.type === 'info'">
        <button mat-button class="btn btn-default" mat-dialog-close>Aceptar</button>
    </div>

    <div class="actions-container" *ngIf="data.type === 'close'">
        <button mat-button class="btn btn-default" mat-dialog-close>{{'BUTTONS.CANCEL' | translate}}</button>
        <button mat-button class="btn btn-primary" [mat-dialog-close]="true" cdkFocusInitial>Salir</button>
    </div>

    <div class="actions-container" *ngIf="data.type === 'delete'">
        <button mat-button class="btn btn-default" mat-dialog-close>{{'BUTTONS.CANCEL' |
            translate}}</button>
        <button mat-button class="btn btn-danger" [mat-dialog-close]="true" cdkFocusInitial>{{'BUTTONS.DELETE' |
            translate}}</button>
    </div>

    <div class="actions-container" *ngIf="data.type === 'yesorno'">
        <button mat-button class="btn btn-default" mat-dialog-close>{{'TEXT.NO' | translate}}</button>
        <button mat-button class="btn btn-primary" [mat-dialog-close]="true" cdkFocusInitial>{{'TEXT.YES' | translate}}</button>
    </div>

    
</div>