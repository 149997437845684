import { TableColumn } from '../shared/table/table.component';

export const SharedInfoColumns: TableColumn[] = [
  {
    field: 'user',
    caption: 'SHARE_INFO_TABLE.USER',
    type: 'regular',
  },
  {
    field: 'email',
    caption: 'Email',
    type: 'regular',
  },
  {
    field: 'company',
    caption: 'SHARE_INFO_TABLE.COMPANIES',
    type: 'regular',
  },
  {
    field: 'shared',
    caption: 'SHARE_INFO_TABLE.SECTIONS_SHARED',
    type: 'regular',
  },
  {
    field: 'actions',
    type: 'icon-multi-action',
    caption: 'SHARE_INFO_TABLE.ACTIONS',
    multiActionIcons: [
      {
        icon: 'edit',
        actionType: 'edit',
        actionTitle: 'LABELS.EDIT',
      },
      {
        actionType: 'delete',
        icon: 'delete_forever',
        actionTitle: 'LABELS.DELETE',
      },
    ],
  },
];

export const SharedDocsColumns: TableColumn[] = [
  {
    field: 'user',
    caption: 'SHARE_INFO_TABLE.USER',
    type: 'regular',
  },
  {
    field: 'email',
    caption: 'Email',
    type: 'regular',
  },

  {
    field: 'shared',
    caption: 'SHARE_INFO_TABLE.DOC_SHARED',
    type: 'regular',
  },
  {
    field: 'actions',
    type: 'icon-multi-action',
    caption: 'SHARE_INFO_TABLE.ACTIONS',
    multiActionIcons: [
      {
        actionType: 'delete',
        icon: 'delete_forever',
        actionTitle: 'LABELS.DELETE',
      },
    ],
  },
];

export const ShareInfoTableES = {
  USER: 'Usuario',
  COMPANIES: 'Sociedades',
  SECTIONS_SHARED: 'Secciones Compartidas',
  ACTIONS: 'Acciones',
  DESCRIPTION:
    'Aquí va a poder seleccionar que información quieres compartir a los usuarios invitados.',
  SELECTION: 'Seleccione la información a compartir',
  DOC_SHARED: 'Carpeta / Documento Compartido',
  COMPANY_PANEL: 'Panel Societario',
  LIBRARY: 'Biblioteca',
};

export const ShareInfoTableEN = {
  USER: 'User',
  COMPANIES: 'Companies',
  SECTIONS_SHARED: 'Shared sections',
  ACTIONS: 'Actions',
  DESCRIPTION:
    'Here you will be able to select what information you want to share with client users.',
  SELECTION: 'Select the information you want to share',
  DOC_SHARED: 'Folder / File Shared',
  COMPANY_PANEL: 'Corporate Panel',
  LIBRARY: 'Library',
};

export interface CompanyShared {
  user: string;
  email: string;
  companies: string;
  shared: string;
}

export interface DocShared {
  user: string;
  email: string;
  shared: string;
  filemanager_guid: string;
}

export interface User {
  id: string;
  name: string;
  email: string;
  description: string;
}

export interface Company {
  id: string;
  name: string;
  description: string;
}
