import { Injectable, OnDestroy, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResult } from 'src/app/core/models/api-result.model';
import { baseFilter } from 'src/app/core/helpers/http-extenstions';
import { environment } from 'src/environments/environment';
import { Users } from 'src/app/core/models/users.model';
import { ITableState, TableResponseModel, TableService } from 'src/app/modules/shared/crud-table';

export enum RoleName {
    c = 'cliente',
    f = 'firmante',
    e = 'editor',
    a = 'administrador',
}

@Injectable({
    providedIn: 'root'
})
export class UsersService extends TableService<Users> implements OnDestroy {
    API_URL = `${environment.apiUrl}Users/top/100`;
    userRole: RoleName;
   
    constructor(@Inject(HttpClient) http) {
        super(http);
        if (localStorage.getItem('userRole')) {
            this.userRole = RoleName[localStorage.getItem('userRole')];
        }
    }

    // READ
    find(tableState: ITableState): Observable<TableResponseModel<Users>> {
        return this.http.get<ApiResult>(this.API_URL).pipe(
            map((response: ApiResult) => {
                const filteredResult = baseFilter(response.result, tableState);
                const result: TableResponseModel<Users> = {
                    items: filteredResult.items,
                    total: filteredResult.total
                };
                return result;
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sb => sb.unsubscribe());
    }

    setUserRole(roleId: string) {
        localStorage.setItem('userRole', roleId);
        this.userRole = RoleName[roleId];
    }

    removeUserRole() {
        localStorage.removeItem('userRole');
        this.userRole = null;
    }

    getUserRole(): RoleName {
        return this.userRole;
    }

    isTheUser(role: string) {
        return role === this.userRole;
    }
}
