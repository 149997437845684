import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ButtonsComponent } from "./buttons.component";
import { TranslateModule } from "@ngx-translate/core";
import { SaveButtonComponent } from './save-button/save-button.component';

@NgModule({
  declarations: [ButtonsComponent, SaveButtonComponent],
  imports: [CommonModule, TranslateModule],
  exports: [ButtonsComponent],
})
export class ButtonsModule {}
