import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IndividualsService } from '../individuals.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-individual-profile',
  templateUrl: './individual-profile.component.html',
  styleUrls: ['./individual-profile.component.scss']
})
export class IndividualProfileComponent implements OnInit {

  constructor(
    private individualsService: IndividualsService,
    private activatedRoute: ActivatedRoute,
    private ref: ChangeDetectorRef
  ) { }

  currentID;
  prefixes;
  individualData;
  isLoading = true;

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((res) => {
      if (res.id !== 'add') {
        this.currentID = res.id;
        this.getInfoIndividual();
      } else {
        this.currentID = null;
        this.individualData = null;
        this.isLoading = false;
      }
    });
  }

  getInfoIndividual() {
    this.isLoading = true;
    this.individualsService
      .getIndividualInfo(this.currentID)
      .subscribe((res) => {
        this.individualData = res;
        this.isLoading = false;
        this.ref.markForCheck();
      });
  }
}
