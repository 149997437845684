<div class="navbar">

    <button [matMenuTriggerFor]="lenguageSelector" class="profile-button" *ngIf="true">
        <div class="{{selectedLanguage.icon}} icon"></div>
    </button>

    <mat-menu #lenguageSelector="matMenu" xPosition="before">
        <button mat-menu-item *ngFor="let item of languageSelector" (click)="setLenguageBycountryCode(item)">
            <div class="item-button">
                <div class="{{item.icon}} icon bajo"></div>
                <div>
                    <p>{{item.name}}</p>
                </div>
            </div>
        </button>
    </mat-menu>


    <button [matMenuTriggerFor]="menu" class="profile-button">
        <div class="profile-initial">
            <div class="user icon">
                <p style="padding-top: 3px;"> <b>{{userInfo?.email[0]| uppercase}}</b></p>
            </div>
        </div>
    </button>
    <mat-menu #menu="matMenu" xPosition="before">
        <div class="user-info">
            <h3>{{userInfo?.firm_name}}</h3>
            <p class="color-primary">{{userInfo?.email}}</p>
        </div>

        <ng-container>
            <button *ngFor="let item of profileButtons" mat-menu-item (click)="onClickMenu(item.key)">
                <div class="item-button">
                    <div class="{{item.icon}} icon bajo"></div>
                    <div>
                        <p style="min-width : 170px">
                            {{item.name | translate}}
                        </p>
                    </div>
                </div>
            </button>
        </ng-container>

        <div class="logout-button">
            <app-buttons type='signout' label="{{'USER_MENU.LOGOUT' | translate}}" (clickEmit)="logOut()"></app-buttons>
        </div>
    </mat-menu>
</div>