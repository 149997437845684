import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndividualsRoutingModule } from './individuals.routing';
import {
  NgbDatepickerModule,
  NgbModalModule,
  NgbCollapseModule,
} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { SharedModule } from '../shared/shared.module';
import { CRUDTableModule } from '../shared/crud-table';
import { ListIndividualsComponent } from './list-individuals/list-individuals.component';
import { CreateComponent } from './create/create.component';
import { ProfileLegalComponent } from './profile-legal/profile-legal.component';
import { CustomAutocompleteModule } from '../shared/custom-autocomplete/custom-autocomplete.module';
import { BindingsIndividualComponent } from './bindings-individual/bindings-individual.component';
import { ModalIndividualsComponent } from './modal-individuals/modal-individuals.component';
import { MatDialogModule } from '@angular/material/dialog';
import { IndividualProfileComponent } from './individual-profile/individual-profile.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { LinkagesIndividualComponent } from './individual-profile/linkages-individual/linkages-individual.component';
import { DataIndividualComponent } from './individual-profile/data-individual/data-individual.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { IndividualAddressComponent } from './individual-profile/data-individual/individual-address/individual-address.component';
import { IndividualDocumentsComponent } from './individual-profile/data-individual/individual-documents/individual-documents.component';
import { IndividualFormComponent } from './individual-profile/data-individual/individual-form/individual-form.component';

@NgModule({
  declarations: [
    ListIndividualsComponent,
    CreateComponent,
    ProfileLegalComponent,
    BindingsIndividualComponent,
    ModalIndividualsComponent,
    IndividualProfileComponent,
    LinkagesIndividualComponent,
    DataIndividualComponent,
    IndividualAddressComponent,
    IndividualDocumentsComponent,
    IndividualFormComponent,
  ],
  imports: [
    CommonModule,
    IndividualsRoutingModule,
    NgbDatepickerModule,
    NgbModalModule,
    NgbCollapseModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatPaginatorModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatSelectModule,
    SharedModule,
    CRUDTableModule,
    CustomAutocompleteModule,
    MatDialogModule,
    MatSidenavModule, MatExpansionModule
  ],
  exports: [CreateComponent, BindingsIndividualComponent],
})
export class IndividualsModule { }
