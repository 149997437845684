export const langOptions: langOption[] = [
  {
    lang: 'EN',
    icon: 'english',
    name: 'English',
  },
  {
    lang: 'ES',
    icon: 'spanish',
    name: 'Español',
  },
];

export interface langOption {
  lang: string;
  icon: string;
  name: string;
}
