import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ExternalAuthService } from './external-auth.service';

@Injectable()
export class ExternalAuthInterceptor implements HttpInterceptor {
  constructor(private authService: ExternalAuthService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    try {
      const url_string = window.document.location.href;
      const url = new URL(url_string);
      const key4Token = url.searchParams.get('t');

      if (key4Token !== null) {
        this.authService.getTokenByKey();
      }
      const token = sessionStorage.getItem('paramT'); 

      if (this.isTokenExpired(token)) {
        this.authService.logout(11);
      }

      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    } catch (error) {}

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    );
  }

  isTokenExpired(token) {
    const expiry = JSON.parse(atob(token.split('.')[1])).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }
}
