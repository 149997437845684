import { animate, style, transition, trigger } from '@angular/animations';

export const Fadein = [
  trigger('fadein', [
    transition('void => *', [
      style({
        opacity: 0,
      }),
      animate(300),
    ]),
  ]),
];
