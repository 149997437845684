import { TableColumn } from '../../shared/table/table.component';

export const DocFormationTable: TableColumn[] = [
  {
    field: 'name',
    caption: 'DOC_FORMATION_TABLE.DOC_NAME',
    type: 'regular',
  },
  {
    field: 'procedures_types',
    caption: 'DOC_FORMATION_TABLE.DOC_TYPE',
    type: 'regular',
  },
  {
    field: 'modified_on',
    caption: 'DOC_FORMATION_TABLE.DOC_DATE',
    type: 'date',
  },

  {
    field: 'has_files',
    type: 'icon-multi-action',
    caption: 'Acciones',
    multiActionIcons: [
      {
        actionType: 'download',
        icon: 'download',
        actionTitle: 'BUTTONS.DOWNLOAD',
      },
      {
        actionType: 'delete',
        icon: 'delete_forever',
        actionTitle: 'BUTTONS.DELETE',
      },
    ],
  },
];

export const docFormationEN = {
  DOC_NAME: 'Document name',
  DOC_TYPE: 'Document type',
  DOC_DATE: 'Document date',
  ACTIONS: 'Actions',
};

export const docFormationES = {
  DOC_NAME: 'Nombre del documento',
  DOC_TYPE: 'Tipo documento',
  DOC_DATE: 'Fecha del documento',
  ACTIONS: 'Acciones',
};
