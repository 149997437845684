import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  ChangeDetectorRef,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { Fadein } from "src/app/animations";
import { CountrySettingService } from "src/app/core/countries-managment/country-settings.service";
import { LocationsService } from "./locations.service";

@Component({
  selector: "app-locations",
  templateUrl: "./locations.component.html",
  animations: [Fadein],
})
export class LocationsComponent implements OnInit, OnChanges {
  @Input() parentFormGroup: FormGroup;
  @Input() colMd = 3;
  @Input() hasParentFormGroup = true;

  @Input() optionalLocation: boolean;
  @Input() isArgHead_country: boolean;

  @Output() provinceLoaded = new EventEmitter<any>();
  @Output() locationLoaded = new EventEmitter<any>();

  provinces = [];
  locations = [];
  Locationcontrol: FormControl;
  filteredStreets: Observable<string[]>;
  hasLocation = true;

  provIdOriginal: any;
  locIdOriginal: any;

  @Input() countryID;
  @Input() countryCode;

  constructor(
    private countrySettingService: CountrySettingService,
    private locationsService: LocationsService,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit() { }

  ngOnChanges(): void {
    if (this.optionalLocation) this.hasLocation = false;

    if (this.parentFormGroup) {
      this.provIdOriginal = this.parentFormGroup.get("province_id").value;
      this.locIdOriginal = this.parentFormGroup.get("location_id").value;
      if (this.locIdOriginal > 0) this.hasLocation = true;
    }

    if (!this.countryID) this.countryID = this.countrySettingService.getCurrentCountryID();
    if (!this.countryCode) this.countryCode = this.countrySettingService.getCurrentCountryCode();
    if (this.isArgHead_country) this.countryID = 11;

    this.Locationcontrol = new FormControl("", Validators.required);
    this.getCountryData();
    this.filteredStreets = this.Locationcontrol.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
  }

  onCheckLocation(e) {
    this.hasLocation = e.checked;
    if (!this.hasLocation) {
      this.parentFormGroup.get("location_id").setValue(-1);
    } else {
      this.parentFormGroup.setValidators([Validators.required]);
    }
  }

  getCountryData() {
    this.locationsService.getProvincesByID(this.countryID).subscribe((res) => {
      this.provinces = [...res];
      this.ref.markForCheck();
      this.onChangeProvince(false);
    });
  }

  onChangeProvince(isExternal) {
    const province_id = this.parentFormGroup.get("province_id").value;

    this.provinceLoaded.emit(
      this.provinces.filter(
        (province) => province.key.province_id === province_id
      )[0]?.key
    );

    if (province_id) {
      this.parentFormGroup.get("province_id").markAsTouched();
      if (province_id) {
        this.locationsService
          .getLocationsbyProvince(province_id)
          .subscribe((response) => {
            this.locations = [...response];

            if (!this.locIdOriginal && !isExternal) {
              this.onChangeLocation(
                this.locations[0].id,
                this.locations[0].zip_code
              );
            } else if (this.locIdOriginal && this.countryCode === "CL") {
              this.onChangeLocation(
                this.parentFormGroup.get("location_id").value,
                null
              );
            } else {
              this.onChangeLocation(
                this.parentFormGroup.get("location_id").value,
                this.parentFormGroup.get("postal_code").value
              );
              this.locIdOriginal = null;
            }

            this.locationLoaded.emit(
              this.locations.filter(
                (location) =>
                  location.id === this.parentFormGroup.get("location_id").value
              )[0]
            );
          });
      }
    }
  }

  onChangeLocation(locationId, zipcode) {
    if (this.hasLocation) {
      const location = this.locations.find((loc: any) => loc.id == locationId);
      this.parentFormGroup
        .get("location_id")
        .patchValue(locationId, { emit: true });

      this.locationLoaded.emit(
        this.locations.filter((location) => location.id === locationId)[0]
      );

      if (location) {
        this.Locationcontrol.patchValue(location.name);
        this.Locationcontrol.markAsTouched();
      }
      if (this.countryCode !== "CL") {
        this.parentFormGroup
          .get("postal_code")
          ?.patchValue(zipcode, { emit: true });
        if (locationId == 1) {
          this.parentFormGroup.get("postal_code")?.enable();
        } else {
          if (this.countryCode !== "UY") {
            this.parentFormGroup.get("postal_code")?.disable();
          } else {
            this.parentFormGroup.get("postal_code")?.enable();
          }
        }
      }
    } else {
      this.parentFormGroup.get("location_id").patchValue(-1, { emit: true });
      this.locationLoaded.emit(-1);
    }
  }

  onLocationChange(e) {
    const location = e.option.value;
    this.onChangeLocation(location.id, location.zip_code);
  }

  public _filter(value: any): string[] {
    if (value) {
      const filterValue = this._normalizeValue(
        typeof value === "string" ? value : value.name
      );
      return this.locations.filter((street) =>
        this._normalizeValue(street.name).includes(filterValue)
      );
    }
  }

  private _normalizeValue(value: string): string {
    return value?.toLowerCase().replace(/\s/g, "");
  }

  isControlValid(controlName: string): boolean {
    const control = this.parentFormGroup?.controls[controlName];
    if (control) return control.valid && (control.dirty || control.touched);
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.parentFormGroup?.controls[controlName];
    if (control) return control.invalid && (control.dirty || control.touched);
  }
}
