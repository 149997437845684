// Localization is based on '@ngx-translate/core';
// Please be familiar with official documentations first => https://github.com/ngx-translate/core

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { English } from './vocabs/EN';
import { Spanish } from './vocabs/ES';

export interface Locale {
  lang: string;
  data: any;
}

const LOCALIZATION_LOCAL_STORAGE_KEY = 'language';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  // Private properties
  private langIds: any = [];
  private lang = 'ES';

  constructor(private translate: TranslateService) {
    // add new langIds to the list
    this.translate.addLangs(['ES', 'EN']);
    this.initLenguage();
  }

  initLenguage() {
    // this language will be used as a fallback when a translation isn't found in the current language
    let storageLang = this.getSelectedLanguage();
    this.translate.setDefaultLang(storageLang ? `${storageLang}` : 'ES');
  }

  setDefaultLanguage(lang) {
    this.lang = lang ? lang : this.lang;
    this.translate.setDefaultLang(`${this.lang}`);
  }

  loadTranslations(): void {
    const locales = [Spanish, English];
    locales.forEach((locale) => {
      // use setTranslation() with the third argument set to true
      // to append translations instead of replacing them
      this.translate.setTranslation(locale.lang, locale.data, true);
      this.langIds.push(locale.lang);
    });

    // add new languages to the list
    this.translate.addLangs(this.langIds);
  }

  setLanguage(lang) {
    localStorage.setItem(LOCALIZATION_LOCAL_STORAGE_KEY, lang);
  }
  
  getSelectedLanguage(): any {
    return localStorage.getItem(LOCALIZATION_LOCAL_STORAGE_KEY);
  }
}
