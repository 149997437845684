import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputFileComponent } from './input-file/input-file.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CurrencyInputMaskDirective } from './directives/currency-input-mask.directive';
import { DisableControlDirective } from './directives/disabled-control.directive';
import { GetIconPipe } from './pipes/get-icon.pipe';
import { TableComponent } from './table/table.component';
import { RouterModule } from '@angular/router';
import { InlineSVGModule } from 'ng-inline-svg';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DocumentTypesComponent } from './document-types/document-types.component';

import { CustomMaterialModule } from './modules/material.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { LocationsComponent } from './locations/locations.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MultiUploadComponent } from './multi-upload/multi-upload.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LongTextPipe } from './pipes/long-text.pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { FirstLetterPipe } from './pipes/first-letter.pipe';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';
import { UserDropdownInnerComponent } from './user-dropdown-inner/user-dropdown-inner.component';
import { TranslationModule } from 'src/app/core/countries-managment/i18n/translation.module';
import { DateAndHourPickerComponent } from './date-and-hour-picker/date-and-hour-picker.component';

import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MatDatetimepickerModule,
  MatNativeDatetimeModule,
} from '@mat-datetimepicker/core';
import { CustomRoundPipe } from './pipes/custom-round.pipe';
import { CustomExpandedAccordionComponent } from './custom-expanded-accordion/custom-expanded-accordion.component';

@NgModule({
  declarations: [
    InputFileComponent,
    CurrencyInputMaskDirective,
    DisableControlDirective,
    GetIconPipe,
    FirstLetterPipe,
    TableComponent,
    DocumentTypesComponent,
    LocationsComponent,
    MultiUploadComponent,
    LongTextPipe,
    CustomRoundPipe,
    ConfirmationModalComponent,
    ScrollTopComponent,
    UserDropdownInnerComponent,
    DateAndHourPickerComponent,
    CustomExpandedAccordionComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    InlineSVGModule,
    MatProgressSpinnerModule,
    CustomMaterialModule,
    ReactiveFormsModule,
    TranslationModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatSidenavModule,
    MatTooltipModule,
    MatDialogModule,
    MatDatetimepickerModule,
    MatNativeDatetimeModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  providers: [],
  exports: [
    InputFileComponent,
    TableComponent,
    CurrencyInputMaskDirective,
    DisableControlDirective,
    GetIconPipe,
    DocumentTypesComponent,
    TranslationModule,
    ReactiveFormsModule,
    LocationsComponent,
    MultiUploadComponent,
    LongTextPipe,
    CustomRoundPipe,
    FirstLetterPipe,
    ScrollTopComponent,
    UserDropdownInnerComponent,
    DateAndHourPickerComponent,
    CustomExpandedAccordionComponent,
  ],
})
export class SharedModule {}
