<div class="individual-data-container">
    <div class="name-container">
        <div class="initials-container">
            <div class="initials">
                <span *ngIf="isLoading" class="spinner spinner-primary ml-5" style="padding: 17px;"></span>
                <span *ngIf="!isLoading" [@fadein]>
                    <span style="margin: 0;">
                        {{formData?.initials | uppercase}}
                    </span>
                </span>
            </div>
        </div>
        <div>
            <h2 style="margin: 0;">{{formData?.fullname}}</h2>
            <h3 style="margin: 0;">{{formData?.description | translate}}</h3>
        </div>
    </div>

    <hr>

    <div class="form-Cointainer custom-scrollbar">
        <mat-expansion-panel style="width: 100%;">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{'INDV.DATA' | translate}}
                </mat-panel-title>
            </mat-expansion-panel-header>

            <app-individual-form [individualData]="individualData" [IndividualType]="IndividualType"
                (updateDataForm)="updateDataForm($event)"></app-individual-form>

        </mat-expansion-panel>
        <br>

        <mat-expansion-panel style="width: 100%;">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{'INDV.ADDRESS' | translate}}
                </mat-panel-title>
            </mat-expansion-panel-header>

            <app-individual-address [individualData]="individualData" [countryCode]="countryCode"
                (updateDataAddress)="updateAddress($event)"></app-individual-address>

        </mat-expansion-panel>
        <br>
        <mat-expansion-panel style="width: 100%;">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{'INDV.ATTACH_DOCS' | translate}}
                </mat-panel-title>
            </mat-expansion-panel-header>

            <app-individual-documents [IndividualType]="IndividualType" [IndividualData]="individualData"
                [countryCode]="countryCode" (updateDocumentsFiles)="updateDocuments($event)">
            </app-individual-documents>

        </mat-expansion-panel>


        <div class="btns-container">
            <button *ngIf="individualData && individualData.vinculate_company_id === 0 && IndividualType === 1" mat-button
                class="btn btn-primary" style="width: 150px;" (click)="newSociety()">{{'LABELS.CONVERT_TO' | translate}}</button>
            <button (click)="updateData(false)" style="margin-left: 10px;" class="btn btn-primary"
                [disabled]="isInvalidFormData">
                <span *ngIf="!loadingSave">{{'BUTTONS.SAVE' | translate}}</span>
                <span *ngIf="loadingSave" style="padding: 17px;" class="spinner spinner-secondary ml-5">
                </span>
            </button>
        </div>
    </div>
</div>