import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-individual-address',
  templateUrl: './individual-address.component.html',
  styleUrls: ['./individual-address.component.scss']
})
export class IndividualAddressComponent implements OnChanges {

  addressFormGroup: FormGroup;
  @Input() individualData;
  @Input() countryCode;
  @Output() updateDataAddress: EventEmitter<any> = new EventEmitter();

  constructor(private _formBuilder: FormBuilder) { }

  ngOnChanges(): void {
    this.addressFormGroup = this._formBuilder.group({
      address: [this.individualData?.address],
      postal_code: [this.individualData?.postal_code],
      location_id: [this.individualData?.location_id],
      province_id: [this.individualData?.province_id],
      floor: [this.individualData?.floor],
      address_number: [this.individualData?.address_number],
      department: [this.individualData?.department],
    });

    this.addressFormGroup.valueChanges.subscribe(value => {
      Object.keys(value).forEach(key => {
        if (!value[key]) delete value[key];
      });
      this.updateDataAddress.emit(value);
    });
  }

}
