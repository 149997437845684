import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { RestService } from 'src/app/core/services/rest.service';

@Injectable({
  providedIn: 'root',
})
export class LocationsService {
  constructor(private restService: RestService) {}
  getProvincesByID(countryId) {
    
    return this.restService.get_api(`locations/group/${countryId ? countryId : '11'}`).pipe(
      map((res) => {
        if (res.success) {
          return res.result;
        } else {
          return [];
        }
      })
    );
  }

  getLocationsbyProvince(provinceId) {
    if (provinceId) {
      return this.restService.get_api(`locations/province/${provinceId}`).pipe(
        map((res) => {
          if (res.success) {
            return res.result;
          } else {
            return [];
          }
        })
      );
    }
  }
}
