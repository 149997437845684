export enum TipoDocumento {
  CambioSede = 1,
  ReformaEstatuto = 3,
  Directorios = 4,
  Poderes = 5,
  EECC = 6,
  Capital = 7,
  RevocarPoder = 10,
  MultitramiteEECC = 15,
  Accionistas = 90,
  Personas = 91
}
