<mat-tab-group class="tabs-container">

    <mat-tab *ngFor="let tab of tabsLinkages" label="{{tab.label| translate}}">
        <div class="linkages-container custom-scrollbar">
            <div *ngIf="tab.links?.length <= 0">
                <br>
                {{'INDV.NO_LINKS' | translate}}
            </div>
            <div *ngFor="let item of tab.links">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{item.company_name}} ({{item.total}})
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div [ngSwitch]="tab.id">
                        <app-table *ngSwitchCase="'powers_companies'" [dataSource]="item.powers" [columns]="powerTable"
                            (actionClicked)="handleClick($event , 'power' )"></app-table>

                        <app-table *ngSwitchCase="'shaerholder_linkages'" [dataSource]="item.stocks"
                            [columns]="shareholderTable"
                            (actionClicked)="handleClick($event , 'shareholder' )"></app-table>

                        <app-table *ngSwitchCase="'auth_linkages'" [dataSource]="item.authorities" [columns]="authTable"
                            (actionClicked)="handleClick($event , 'directory' )"></app-table>
                    </div>
                </mat-expansion-panel>
                <br>
            </div>
        </div>
    </mat-tab>
</mat-tab-group>