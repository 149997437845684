import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { first } from 'rxjs/operators';
import { DialogConfirmComponent } from '../../shared/dialog/dialog-confirm.component';
import { TableActionPayload } from '../../shared/table/table.component';
import { columnLegalPerson, columnsPerson } from '../individuals';
import { IndividualsService } from '../individuals.service';
import { ModalIndividualsComponent } from '../modal-individuals/modal-individuals.component';

@Component({
  selector: 'app-list-individuals',
  templateUrl: './list-individuals.component.html',
  styleUrls: ['./list-individuals.component.scss'],
})
export class ListIndividualsComponent implements OnInit {
  tabIndex = 0;
  isLoading = false;
  name_text = '';
  last_name_text = '';
  page = 1;
  size = 5;
  total = 0;
  individuals = [];
  columnsLegals = columnLegalPerson;
  columnsPerson = columnsPerson;

  constructor(
    private individualsService: IndividualsService,
    private ref: ChangeDetectorRef,
    private _snackbar: MatSnackBar,
    private _matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getIndividuals();
  }

  tabChange(e) {
    this.individuals = [];
    this.tabIndex = e.index;
    this.name_text = '';
    this.last_name_text = '';
    this.page = 1;
    this.size = 5;
    this.getIndividuals();
  }

  changePage(e) {
    this.page = e.pageIndex + 1;
    this.size = e.pageSize;
    this.getIndividuals();
  }

  getIndividuals() {
    this.isLoading = true;
    this.ref.markForCheck();
    this.individualsService
      .getIndividuals(this.page, this.size, this.tabIndex, this.name_text, this.last_name_text)
      .subscribe((res) => {
        this.isLoading = false;
        this.total = res.total;
        this.individuals = res.result;
        this.ref.markForCheck();
      });
  }

  searchForm(text, type: 'name' | 'lastname') {
    if (type === 'name') this.name_text = text;
    if (type === 'lastname') this.last_name_text = text;
    this.page = 1;
    this.getIndividuals();
  }

  handleClick(event: TableActionPayload) {
    if (event.action === 'delete') {
      this.deleteIndividual(event.payload.id).then();
    }
    if (event.action === 'download') {
      const config = new MatDialogConfig();
      config.data = {
        payload: event.payload,
      };

      this._matDialog
        .open(ModalIndividualsComponent, config)
        .afterClosed()
        .subscribe(
          (res) => {
            this.downloadIndividualReport(
              event.payload.id,
              event?.payload?.fullname,
              res
            );
          },
          (err) => {
            this._snackbar.open(
              'Hubo un error al descargar el archivo',
              'cerrar',
              {
                duration: 4000,
              }
            );
          }
        );
    }
  }

  downloadIndividualReport(id, name, options) {
    this.individualsService.getExcelIndividual(id, name, options).subscribe(
      (response) => {
        this._snackbar.open('Archivo descargado correctamente', 'cerrar', {
          duration: 4000,
        });
      },
      (err) => {
        this._snackbar.open('Hubo un error al descargar el archivo', 'cerrar', {
          duration: 4000,
        });
      }
    );
  }

  downloadFullReport() {
    this._matDialog.open(ModalIndividualsComponent);
  }

  async deleteIndividual(id: string) {
    const config = new MatDialogConfig();
    config.data = {
      title: 'La persona ha firmado documentos.',
      text: 'Será eliminado permanentemente y no se podrá recuperar. ¿Desea continuar?',
    };
    try {
      const { success, result } = await this.individualsService
        .deleteIndividual(id)
        .toPromise();
      if (success) {
        this.getIndividuals();
        this._snackbar.open('Persona eliminada correctamente', 'cerrar', {
          duration: 4000,
        });
      } else {
        if (result === 'La persona es firmante de algunos documentos') {
          this._matDialog
            .open(DialogConfirmComponent, config)
            .afterClosed()
            .pipe(first())
            .toPromise()
            .then(async (choice) => {
              if (choice === 1) {
                await this.removeSigner(id);
              }
            });
        } else {
          this._snackbar.open(result, 'cerrar', {
            duration: 4000,
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  async removeSigner(id: string) {
    try {
      const { success } = await this.individualsService
        .deleteIndividual(id, true)
        .toPromise();
      if (success) {
        this.getIndividuals();
        this._snackbar.open('Persona eliminada correctamente', 'cerrar', {
          duration: 4000,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }
}
