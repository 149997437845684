
export const environment = {
  production: false,
  develop: false,
  appVersion: 'v717qa1',
  userDataKey: 'authf649fc9a5f55',
  isMockEnabled: false,
  authUrl: '//auth.qa.brevity.pro/connect/',
  authToken :'https://auth.qa.brevity.pro/api/token/',
  apiUrl: '//api.qa.brevity.pro/api/',
  portalURL: 'https://portal.qa.brevity.pro/',
  authLogin: 'https://auth.qa.brevity.pro/Login',
  apiPdfUrl: '//generator.qa.brevity.pro/',
  apiUrlDownloads: '//api.qa.brevity.pro/api/',
  amplitudeApiKey: 'b7cf769514216bfa465046c6a3735e68',
  goDiagramlicenseKey:
  "288647e1b1614ac702d90776423d68f919a574609e841ca0090445f7ee096b46729dea7059d78ad2c2fe4bac1f7d908ade913d2b924c0632e335d28c10e6d3feb23773b4430a42def0062690cbf878a7fd7070e0d2b322f2dc78dea2eabad39f5debf1cb40d50ebe",
};