import { ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { inputForm } from 'src/app/components/form-generator/forms';
import { Fadein } from 'src/app/animations';
import { CountrySettingService } from 'src/app/core/countries-managment/country-settings.service';
import { IndividualsService } from '../../individuals.service';
import { RestService } from 'src/app/core/services/rest.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiResult } from 'src/app/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from 'src/app/modules/shared/confirmation-modal/confirmation-modal.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-data-individual',
  templateUrl: './data-individual.component.html',
  styleUrls: ['./data-individual.component.scss'],
  animations: [Fadein]
})
export class DataIndividualComponent implements OnChanges {

  @Input() individualData;
  @Input() isLoading;

  formData;
  addressData;
  updatedDocs;
  countryCode;
  loadingSave = false;
  IndividualType;
  formDataConfig: inputForm[];
  isInvalidFormData = true;

  constructor(
    private countySettingService: CountrySettingService,
    private individualsService: IndividualsService,
    private restService: RestService,
    private snackbar: MatSnackBar,
    private router: Router,
    private ref: ChangeDetectorRef,
    private translate: TranslateService,
    private dialog: MatDialog) { }

  ngOnChanges() {
    if (!this.individualData) {
      if (!this.isLoading) {
        this.askNewIndividualType();
      }
    } else {
      this.formData = {
        fullname: this.individualData.fullname,
        description: this.individualData.description,
        initials: this.individualData.initials,
        name: this.individualData.name,
        last_name: this.individualData.last_name,
        countries_id: this.individualData.countries_id,
      };
      this.IndividualType = this.individualData?.type_id;
      this.ref.markForCheck();
    }

    this.countySettingService.setCurrentCountryID(this.individualData ? this.individualData.countries_id : 11);
    this.countryCode = this.countySettingService.getCurrentCountryCode();
  }

  updateData(isNewSociety) {
    this.loadingSave = true;
    const value = { ...this.formData, ...this.addressData };
    if (!this.individualData) value.type_id = this.IndividualType;

    delete value.fullname;
    delete value.initials;
    delete value.description;

    if (this.individualData) {
      const data = this.setObject(value);
      this.individualsService
        .updateIndividual(this.individualData.id, data)
        .subscribe((response: ApiResult) => {
          this.loadingSave = false;
          this.ref.markForCheck();
          if (response.success) {
            if (isNewSociety) {
              this.router.navigate([`/create/company/true`], {
                state: {
                  name: this.individualData.fullname,
                  last_name: '',
                  province_id: this.individualData.province_id,
                  location_id: this.individualData.location_id,
                  postal_code: this.individualData.postal_code,
                  address: this.individualData.address,
                  address_number: this.individualData.address_number,
                  floor: this.individualData.floor,
                  department: this.individualData.department,
                  indiv_id: this.individualData.id,
                },
              });
            }
            else {
              this.snackbar.open('Persona actualizada correctamente', 'close', {
                duration: 1500,
              });
            }
          } else {
            this.snackbar.open('Error al generar persona', 'close', {
              duration: 5000,
            });
          }
        });
    } else {
      const data = this.setObjectCreate(value);
      this.individualsService.createIndividual(data).subscribe((res) => {
        this.loadingSave = false;
        this.ref.markForCheck();
        if (res) {
          this.snackbar.open('Persona creada correctamente', 'close', {
            duration: 5000,
          });
          this.router.navigate([`/individuals/profile/${res}`]);

        } else {
          this.snackbar.open('Error al generar persona', 'close', {
            duration: 5000,
          });
        }
      });
    }
  }

  updateAddress(data) {
    this.addressData = data;
    this.checkIfFormDataIsValid();
  }

  updateDocuments(data) {
    this.updatedDocs = data;
    this.checkIfFormDataIsValid();
  }

  checkIfFormDataIsValid() {
    if (this.IndividualType === 0) this.isInvalidFormData = !this.formData?.name || !this.formData?.last_name;
    if (this.IndividualType === 1) this.isInvalidFormData = !this.formData?.name || !this.formData?.countries_id;
  }

  updateDataForm(data) {
    this.isInvalidFormData = data.invalid;
    this.formData = data?.value;
    this.countySettingService.setCurrentCountryID(this.formData.countries_id);
    this.countryCode = this.countySettingService.getCurrentCountryCode();
  }

  setObject(value) {
    let data = this.restService.buildObjectFormData(value, 'value.');
    let documentsKeys;
    if (this.updatedDocs) documentsKeys = Object.keys(this.updatedDocs);

    documentsKeys?.forEach(key => {
      data.append(
        key,
        this.updatedDocs[key],
        this.updatedDocs[key]?.name
      );
    });
    return data;
  }

  setObjectCreate(value) {
    let data = this.restService.buildObjectFormData(value, 'value.');

    if (this.updatedDocs) {
      const documentsKeys = Object.keys(this.updatedDocs);
      documentsKeys?.forEach(key => {
        data.append('value.' + key, this.updatedDocs[key], this.updatedDocs[key]?.name);
      });
    };

    return data;
  }

  askNewIndividualType() {
    this.isLoading = true;
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        text: `${this.translate.instant('LABELS.QUESTION_ADD_PERSON')} ${this.translate.instant('LABELS.LEGAL_ENTITY')}?`,
        type: 'yesorno',
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) { this.IndividualType = 1; } else { this.IndividualType = 0; };
      this.isLoading = false;
      this.ref.markForCheck();
    });
  }

  newSociety() {
    this.updateData(true);
  }
}
