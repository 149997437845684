import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IndividualsService } from '../individuals.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-modal-individuals',
  templateUrl: './modal-individuals.component.html',
  styleUrls: ['./modal-individuals.component.scss'],
})
export class ModalIndividualsComponent implements OnInit {
  FormGroup: FormGroup;

  constructor(
    private _formBuilder: FormBuilder,
    private individualsService: IndividualsService,
    private _snackbar: MatSnackBar,
    public dialogRef: MatDialogRef<ModalIndividualsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit(): void {
    this.setForm();
  }

  setForm() {
    this.FormGroup = this._formBuilder.group({
      authorities: [false, []],
      powers: [false, []],
      stock: [false, []],
    });
  }

  getReport() {
    const options = {
      authorities: this.FormGroup.controls.authorities.value,
      powers: this.FormGroup.controls.powers.value,
      stock: this.FormGroup.controls.stock.value
    };

    this.individualsService.getCompanyExcel(options).subscribe(response => {
      this.dialogRef.close();
    });
  }

  close() {
    this.dialogRef.close();
  }
}
