export const PowersES = {
    CHOOSE_UPLOAD: 'Selecciona como quieres cargar el poder: ',
    SEL_LIB_DOC: 'Seleccionar desde biblioteca',
    UP_DOC: 'Subir un documento',
    WITHOUT_DOC: 'Continuar sin documento',
    UP_DOCUMENT: 'Cargar documento',
    SELECT_DOC : 'Seleccionar documento',
    SELECT : 'Seleccionar',
    SECOND_STEP :' Apoderados, Facultades, Firma y Límites',
    ASK_CLASSES : '¿Este poder contiene clases?',
    SIGN : 'Firma',
    LIMITS : 'Limites',
    ADD_CLASS: 'Añadir clase',
    CLASSNAME: 'Nombre de la clase',
    UP_POWER: 'Cargar Poder',
    NOT_POSSIBLE: 'No es posible mostrar previsualización'
};

export const PowersEN = {
    CHOOSE_UPLOAD: 'Select how you want to load the POA: ',
    SEL_LIB_DOC: 'Select document from Library',
    UP_DOC: 'Upload document',
    WITHOUT_DOC: 'Continue without document',
    UP_DOCUMENT: 'Upload document',
    SELECT_DOC : 'Select document',
    SELECT : 'Select',
    SECOND_STEP :' Proxies, Powers, Signature and Limits',
    ASK_CLASSES : 'Does this power have classes?',
    SIGN : 'Signature',
    LIMITS : 'Limits',
    ADD_CLASS: 'Add class',
    CLASSNAME: 'Name of the class',
    UP_POWER: 'Load power',
    NOT_POSSIBLE: 'Unable to show preview'
};

export const upload_methods = [
    {
        id: 1,
        label: 'POWERS.SEL_LIB_DOC',
        icon: 'library'
    },
    {
        id: 2,
        label: 'POWERS.UP_DOC',
        icon: 'document'
    }, {
        id: 3,
        label: 'POWERS.WITHOUT_DOC',
        icon: 'without-doc'
    }];