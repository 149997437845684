import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UsersService } from 'src/app/core/services/users.services';
import { Observable, throwError } from 'rxjs';
import { AmplitudeService } from '../core/services/amplitude.service';
import { catchError, map } from 'rxjs/operators';
import { ApiResult } from '../core';

const API_AUTH_URL = `${environment.authUrl}`;
const API_TOKEN_URL = `${environment.authToken}`;
const API_URL = `${environment.apiUrl}`;
const IS_DEVELOP = `${environment.develop}`;
@Injectable({
  providedIn: 'root',
})
export class ExternalAuthService {
  token;
  private currentUser;
  private currentUserInfo;

  constructor(
    private http: HttpClient,
    private usersService: UsersService,
    private _amplitudeService: AmplitudeService
  ) {}

  isAuthenticated(): Observable<boolean> {
    return new Observable((observer) => {
      const url_string = window.document.location.href;
      const url = new URL(url_string);
      const key4Token = url.searchParams.get('t');

      if (key4Token) {
        sessionStorage.clear();
      }

      this.token = sessionStorage.getItem('paramT');


      if (!this.token) {
        this.getTokenByKey().subscribe((token) => {
          this.getUserInfoByToken(token).subscribe((userInfo) => {
            this.getUserbyId(userInfo.id).subscribe((user) => {
              observer.next(true);
              observer.complete();
            });
          });
        });
      } else {
        if (!this.currentUserInfo) {
          this.getUserInfoByToken(this.token).subscribe((userInfo) => {
            this.getUserbyId(userInfo.id).subscribe((user) => {
              observer.next(true);
              observer.complete();
            });
          });
        } else {
          observer.next(true);
          observer.complete();
        }
      }
    });
  }

  getTokenByKey() {
    const url_string = window.document.location.href;
    const url = new URL(url_string);
    let key4Token = url.searchParams.get('t');

    return this.http
      .get(`${API_URL}tokens/${key4Token}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(
        map((res: ApiResult) => {
          console.log(res);
          if (res.success) {
            this.token = res.result;
            sessionStorage.setItem('paramT', this.token);
            return res.result;
          } else {
            this.logout('error get token success false ' + res.result);
            throw new Error('Error');
          }
        }),
        catchError((err) => {
          this.logout('error get token ' + err);
          return throwError('Error');
        })
      );
  }

  getUserInfoByToken(token) {
    return this.http
      .get(`${API_AUTH_URL}userinfo`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .pipe(
        map((res: any) => {
          this.userInfoSeted(res);
          this._amplitudeService.setLoggedUser(res);
          this._amplitudeService.setActionEvent('Login', {
            email: res.email,
          });

          return res;
        }),
        catchError((err) => {
          this.logout('error get userInfo by token' + err);
          return throwError('Error');
        })
      );
  }

  getUserbyId(id) {
    return this.http
      .get(`${API_URL}users/${id}`, {
        headers: {
          'Authorization': `Bearer ${this.token}`,
          'Content-Type': 'application/json',
        },
      })
      .pipe(
        map((res: ApiResult) => {
          if (res.success) {
            this.currentUser = res.result;
            this.usersService.setUserRole(res.result.rol_key);
            return res.result;
          } else {
            this.logout('error get user by ID success false' + res.result);
            throw new Error('Error');
          }
        }),
        catchError((err) => {
          this.logout('error get user by ID' + err);
          return throwError('Error');
        })
      );
  }

  getKeyTokenToPortal() {
    let token = sessionStorage.getItem('paramT');
    return this.http
      .post(`${API_URL}tokens/${token}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(
        map((res: ApiResult) => {
          if (res.success) {
            return res.result;
          } else {
            this.logout('error get ID to portal' + res.result);
            return throwError('Error');
          }
        }),
        catchError((err) => {
          this.logout('error get ID to portal' + err);
          return throwError('Error');
        })
      );
  }

  goToPortal() {
    this.getKeyTokenToPortal().subscribe((id: any) => {
      sessionStorage.clear();
      window.location.href = `${environment.portalURL}home-page?t=${id}`;
    });
  }

  logout(info?: any) {
    console.warn('Logout', info);
    sessionStorage.removeItem('paramT');
    if (IS_DEVELOP == 'true') {
    } else {
      window.location.href = environment.authLogin;
    }
  }

  getCurrentUserInfo() {
    this.currentUserInfo.enableSwitchFIRM = this.currentUser.firms_count > 1;
    return this.currentUserInfo;
  }

  getCurrentUser() {
    return this.currentUser;
  }

  switchFirm() {
    this.http
      .put(`${API_URL}users/change_firm`, {
        headers: {
          'Authorization': `Bearer ${this.token}`,
          'Content-Type': 'application/json',
        },
      })
      .subscribe((res: any) => {
        if (res.success) {
          let hash = res.result;
          window.location.href = `${environment.authLogin}/ChangeFirm?hash=${hash}&user=${this.currentUserInfo?.email}`;
        }
      });
  }
  userInfoSeted(info) {
    this.currentUserInfo = info;
  }
}
