export const profileOptionsADMIN = [
  {
    key: 'users',
    icon: 'userssetting',
    name: 'USER_MENU.USERS_SETTINGS',
  },
  {
    key: 'logs',
    icon: 'summary',
    name: 'USER_MENU.LOG_ACCESS',
  },
  {
    key: 'portal',
    icon: 'dooropen',
    name: 'USER_MENU.SWITCH_PORTAL',
  },
  {
    key: 'firm',
    icon: 'exchange',
    name: 'USER_MENU.SWITCH_FIRM',
  },
];
export const userMenuEN = {
  LOGOUT: 'Log out',
  USERS_SETTINGS: 'Users settings',
  SWITCH_PORTAL : 'Portal access',
  LOG_ACCESS: 'Log access',
  SWITCH_FIRM : 'Change Firm'
};

export const userMenuES = {
  LOGOUT: 'Salir',
  USERS_SETTINGS: 'Configuración de usuarios',
  SWITCH_PORTAL : 'Acceder al Portal',
  LOG_ACCESS : 'Acceso a Logs',
  SWITCH_FIRM : 'Cambiar de Firma'
};
