<div id="navbar">
  <app-navbar></app-navbar>
</div>


<!--begin::Main-->

<ng-container *ngIf="selfLayout !== 'blank'; else blankLayout">
  <!-- begin:: Header Mobile -->

  <!-- end:: Header Mobile -->
  <div class="d-flex flex-column flex-root">
    <!--begin::Page-->
    <div class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
      <ng-container *ngIf="asideSelfDisplay">
        <ng-container *ngIf="asideMenuStatic">
          <!--begin::STATIC Aside-->
          <app-aside #ktAside id="kt_aside" class="aside aside-left d-flex flex-column flex-row-auto"
            [ngClass]="asideCSSClasses"></app-aside>
          <!--end::STATIC Aside-->
        </ng-container>
        <ng-container *ngIf="!asideMenuStatic">
          <!--begin::DYNAMIC Aside-->
          <app-aside-dynamic #ktAside id="kt_aside" class="aside aside-left d-flex flex-column flex-row-auto"
            [ngClass]="asideCSSClasses"></app-aside-dynamic>
          <!--end::DYNAMIC Aside-->
        </ng-container>
      </ng-container>
      <!-- end:: Aside Left -->

      <!--begin::Wrapper-->
      <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
        <!-- begin:: Header -->
        <app-header #ktHeader id="kt_header" class="header" [ngClass]="headerCSSClasses"></app-header>
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div class="main-component">
          <!-- begin:: Content Head -->
          <!--<ng-container *ngIf="subheaderDisplay">
            <app-subheader-wrapper></app-subheader-wrapper>
          </ng-container> -->
          <!-- end:: Content Head -->

          <!-- begin:: Content Body -->
          <ng-container *ngIf="contentExtended">
            <router-outlet></router-outlet>
          </ng-container>

          <ng-container *ngIf="!contentExtended">
            <!--begin::Entry-->
            <div class="d-flex">
              <!--begin::Container-->
              <div [ngClass]="contentContainerClasses">
                <router-outlet></router-outlet>
                <!--end::Container-->
              </div>
              <!--<script type="text/javascript" id="hs-script-loader" async defer
                src="//js-na1.hs-scripts.com/8192262.js"></script>-->
            </div>
            <!--end::Entry-->
          </ng-container>
          <!--<script type="text/javascript" id="hs-script-loader" async defer
            src="//js-na1.hs-scripts.com/8192262.js"></script>-->
          <!-- end:: Content Body -->
        </div>
        <!-- end:: Content -->
        <!--<script type="text/javascript" id="hs-script-loader" async defer
          src="//js-na1.hs-scripts.com/8192262.js"></script>-->
      </div>
      <!--end::Wrapper-->
    <!--  <script type="text/javascript" id="hs-script-loader" async defer
        src="//js-na1.hs-scripts.com/8192262.js"></script> -->
    </div>
    <!-- end:: Body -->
   <!-- <script type="text/javascript" id="hs-script-loader" async defer src="//js-na1.hs-scripts.com/8192262.js"></script> -->
    <!--end::Page-->
  </div>
</ng-container>

<ng-template #blankLayout>
  <div class="d-flex flex-column flex-root">
    <router-outlet></router-outlet>
  </div>
</ng-template>
<app-scripts-init></app-scripts-init>
<!--end::Main-->