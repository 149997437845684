import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'customRound',
})
export class CustomRoundPipe extends DecimalPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (Number.isInteger(+value)) return value;
    return super.transform(value, args);
  }
}
