import { TableColumn } from "../shared/table/table.component";

export const CompaniesTable: TableColumn[] = [
  {
    field: "following",
    caption: " ",
    svgPathOn: "./assets/media/svg/brevity/favorite-active.svg",
    svgPathOff: "./assets/media/svg/brevity/favorite-inactive.svg",
    type: "toggle-svg-icon",
    actionType: "toggleFavorite",
  },
  {
    field: "company_name",
    caption: "COMPANY_LIST.COMPANY_NAME",
    type: "regular",
    order: true
  },
  {
    field: "country_name",
    caption: "COMPANY_LIST.COUNTRY_ORIGIN",
    type: "regular",
  },
  {
    field: "jurisdictionName",
    caption: "COMPANY_LIST.JURISDICTION",
    type: "regular",
  },
  {
    field: "modification_date",
    caption: "COMPANY_LIST.LAST_MOFIDICATION",
    type: "date",
  },
  {
    field: "validation_date",
    caption: "COMPANY_LIST.LAST_VALIDATION",
    type: "date",
    order: true
  },
  {
    field: "has_files",
    type: "icon-multi-action",
    caption: "LABELS.ACTIONS",
    multiActionIcons: [
      {
        actionType: "navigate",
        icon: "remove_red_eye",
        actionTitle: "COMPANY_LIST.ENTER",
        conditional: true,
        fieldOfCondition: 'active',
      },
      {
        actionType: "active",
        svgIcon: "active",
        actionTitle: "COMPANY_LIST.STATUS_ACTIVE",
        conditional: true,
        fieldOfCondition: 'inactive',
      },
      {
        actionType: "inactive",
        icon: "remove_circle",
        actionTitle: "COMPANY_LIST.STATUS_INACTIVE",
        conditional: true,
        fieldOfCondition: 'active',
      },
      {
        actionType: "delete",
        icon: "delete",
        actionTitle: "COMPANY_LIST.DELETE",
      },
    ]
  }
];

export const companyListES = {
  COMPANY_NAME: "Nombre Sociedad",
  COUNTRY_ORIGIN: "País de Origen",
  JURISDICTION: "Jurisdicción",
  LAST_MOFIDICATION: "Última Modificación",
  LAST_VALIDATION: "Última validación",
  ACTIONS: "Acciones",
  ENTER: "Ver",
  STATUS_ACTIVE: "Activar Sociedad",
  STATUS_INACTIVE: "Desactivar Sociedad",
  DELETE: "Eliminar Sociedad",
};

export const companyListEN = {
  COMPANY_NAME: "Company Name",
  COUNTRY_ORIGIN: "Country of Origin",
  JURISDICTION: "Jurisdiction",
  LAST_MOFIDICATION: "Last mofication",
  LAST_VALIDATION: "Last validation",
  ACTIONS: "Actions",
  ENTER: "Enter",
  STATUS_ACTIVE: "Enable Company",
  STATUS_INACTIVE: "Disable Company",
  DELETE: "Delete Company",
};
