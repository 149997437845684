import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { TipoDocumento } from 'src/app/modules/documents/argentina/multitramite/models/documentType.enum';
import { CurrentFile } from 'src/app/modules/shared/input-file/input-file.component';

@Component({
  selector: 'app-individual-documents',
  templateUrl: './individual-documents.component.html',
  styleUrls: ['./individual-documents.component.scss']
})
export class IndividualDocumentsComponent implements OnChanges {

  addressFile: CurrentFile;
  documentFile: CurrentFile;

  InscriptionFile: CurrentFile;
  ByLawFile: CurrentFile;
  LastInscriptionFile: CurrentFile;

  labelInput = 'LABELS.ID_COPY.AR';
  updatedDocs: any = {};

  @Input() IndividualType;
  @Input() IndividualData;
  @Input() countryCode;
  @Output() updateDocumentsFiles: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnChanges(): void {
    this.labelInput = `LABELS.ID_COPY.${this.countryCode}`;
    this.setPreviousDocs();
  }

  setPreviousDocs() {
    if (this.IndividualData?.document_file_guid) {
      this.documentFile = new CurrentFile();
      this.documentFile.guid = this.IndividualData?.document_file_guid;
      this.documentFile.name = `${this.IndividualData?.name} ${this.IndividualData?.last_name} DNI_Pasaporte`;
      this.documentFile.procedure_type_id = TipoDocumento.Personas;
      this.documentFile.individual_id = this.IndividualData?.id;
    }
    if (this.IndividualData?.address_file_guid) {
      this.addressFile = new CurrentFile();
      this.addressFile.guid = this.IndividualData?.address_file_guid;
      this.addressFile.name = `${this.IndividualData?.name} ${this.IndividualData?.last_name} Documento adicional`;
      this.addressFile.procedure_type_id = TipoDocumento.Personas;
      this.addressFile.individual_id = this.IndividualData?.id;
    }

    if (this.IndividualData?.inscription_file_guid) {
      this.InscriptionFile = new CurrentFile();
      this.InscriptionFile.guid = this.IndividualData?.inscription_file_guid;
      this.InscriptionFile.name = `${this.IndividualData?.name} Constancia de inscripción`;
      this.InscriptionFile.procedure_type_id = TipoDocumento.Personas;
      this.InscriptionFile.individual_id = this.IndividualData?.id;
    }
    if (this.IndividualData?.bylaw_file_guid) {
      this.ByLawFile = new CurrentFile();
      this.ByLawFile.guid = this.IndividualData?.bylaw_file_guid;
      this.ByLawFile.name = `${this.IndividualData?.name} Estatuto`;
      this.ByLawFile.procedure_type_id = TipoDocumento.Personas;
      this.ByLawFile.individual_id = this.IndividualData?.id;
    }
    if (this.IndividualData?.last_inscription_file_guid) {
      this.LastInscriptionFile = new CurrentFile();
      this.LastInscriptionFile.guid = this.IndividualData?.last_inscription_file_guid;
      this.LastInscriptionFile.name = `${this.IndividualData?.name}  Última inscripción`;
      this.LastInscriptionFile.procedure_type_id = TipoDocumento.Personas;
      this.LastInscriptionFile.individual_id = this.IndividualData?.id;
    }
  }

  fileUploaded(file: File, type: string) {
    switch (type) {
      case 'document':
        this.updatedDocs.FormDocumentFile = file;
        break;
      case 'others':
        this.updatedDocs.FormAddressFile = file;
        break;
      case 'inscription':
        this.updatedDocs.FormInscriptionFile = file;
        break;
      case 'byLaw':
        this.updatedDocs.FormByLawFile = file;
        break;
      case 'lastInscription':
        this.updatedDocs.FormLastInscriptionFile = file;
        break;
    };
    this.updateDocumentsFiles.emit(this.updatedDocs);
  }

}
