<!--begin::Brand-->
<div class="brand flex-column-auto" id="kt_brand">
  <a *ngIf="header_logo" routerLink="/companies/list" class="brand-logo">
    <img class="img-logo" alt="Logo" [attr.src]="header_logo" />
  </a>
  <label class="header-name" *ngIf="header_name">{{header_name}}</label>

  <ng-container >
    <button class="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
      <span [inlineSVG]="'./assets/media/svg/icons/Navigation/Angle-double-left.svg'" cacheSVG="true"
        class="svg-icon svg-icon-xl"> aca </span>
    </button>
  </ng-container>
</div>
<!--end::Brand-->

<!--begin::Aside Menu-->
<div class="aside-menu-wrapper flex-column-fluid" id="kt_aside_menu_wrapper">
  <!--begin::Menu Container-->
  <div id="kt_aside_menu" class="aside-menu" [ngClass]="asideMenuCSSClasses" data-menu-vertical="1"
    [attr.data-menu-dropdown]="asideMenuDropdown" data-menu-dropdown-timeout="500">
    <!-- begin::Menu Nav -->
    <ul class="menu-nav" [ngClass]="ulCSSClasses">
      <!-- begin::1 Level -->

      <li class="menu-item" aria-haspopup="true" routerLinkActive="menu-item-active">
        <a class="menu-link" routerLink="/library" routerLinkActive="active" (click)="onClickMenu('Biblioteca')">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 4H6C5.44772 4 5 4.44772 5 5V7H14V5C14 4.44772 13.5523 4 13 4Z" fill="#2F2E32" />
            <path d="M3 9V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V11C21 9.89543 20.1046 9 19 9H3Z"
              fill="#197EF8" />
          </svg>
          <span class="menu-text">{{'MENU.LIBRARY' | translate}}</span>
        </a>
      </li>
      <!--li class="menu-item" aria-haspopup="true" routerLinkActive="menu-item-active">
        <a class="menu-link" routerLink="/docs/list" routerLinkActive="active" (click)="onClickMenu('Documentos')">
          <i class="material-icons md-24 icon-menu">description</i>
          <span class="menu-text">{{'MENU.DOCUMENTS' | translate}}</span>
        </a>
      </li!-->

      <li class="menu-item" aria-haspopup="true" routerLinkActive="menu-item-active">
        <a class="menu-link" routerLink="/map" routerLinkActive="active" (click)="onClickMenu('mapaSocietario')">
          <i class="material-icons md-22 icon-menu-min">
            account_tree
          </i>
          <span class="menu-text">{{'MENU.CORPOTATE_MAP' | translate}}</span>
        </a>
      </li>


      <li class="menu-item" aria-haspopup="true" routerLinkActive="menu-item-active">
        <a class="menu-link" routerLink="/individuals" routerLinkActive="active" (click)="onClickMenu('Personas')">
          <i class="material-icons md-24 icon-menu">people</i>
          <span class="menu-text">{{'MENU.PERSONS' | translate}}</span>
        </a>
      </li>

      <li class="menu-item" aria-haspopup="true" routerLinkActive="menu-item-active">
        <a class="menu-link" routerLink="/companies/list" routerLinkActive="active" (click)="onClickMenu('Sociedades')">

          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 7V19C5 20.1046 5.89543 21 7 21H13C14.1046 21 15 20.1046 15 19V9C15 7.89543 14.1046 7 13 7H5Z"
              fill="#197EF8" />
            <path
              d="M9 5.45V4C9 3.44772 9.44772 3 10 3H18C18.5523 3 19 3.44772 19 4V16.9C19 17.4523 18.5523 17.9 18 17.9H17"
              stroke="#2F2E32" stroke-width="2" stroke-linecap="round" />
          </svg>
          <span class="menu-text">{{'MENU.COMPANIES' | translate}}</span>
        </a>
      </li>

      <!--li class="menu-item" aria-haspopup="true" routerLinkActive="menu-item-active">
        <a class="menu-link" routerLink="/registral/index" routerLinkActive="active" (click)="onClickMenu('Trámites')">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 4H10V8H14V4Z" fill="#197EF8" />
            <path d="M8 16H4V20H8V16Z" fill="#197EF8" />
            <path d="M8 10H4V14H8V10Z" fill="#197EF8" />
            <path d="M8 4H4V8H8V4Z" fill="#197EF8" />
            <path d="M20 4H16V8H20V4Z" fill="#197EF8" />
            <path d="M11 17.86V20H13.1L19.08 14.03L16.96 11.91L11 17.86Z" fill="#B1B1BC" />
            <path d="M14 12.03V10H10V14H12.03L14 12.03Z" fill="#197EF8" />
            <path
              d="M20.8499 11.56L19.4399 10.15C19.2399 9.95 18.9299 9.95 18.7299 10.15L17.6699 11.21L19.7899 13.33L20.8499 12.27C21.0499 12.07 21.0499 11.76 20.8499 11.56Z"
              fill="#B1B1BC" />
          </svg>
          <span class="menu-text">{{'MENU.TRAMITES' | translate}}</span>
        </a>
      </li-->
      <li class="menu-item" aria-haspopup="true" routerLinkActive="menu-item-active">
        <a class="menu-link" routerLink="/expirations/list" routerLinkActive="active"
          (click)="onClickMenu('Vencimientos')">
          <mat-icon style="margin-top: 2px;">notifications</mat-icon>
          <span class="menu-text">{{'MENU.EXPIRATIONS' | translate}}</span>
        </a>
      </li>


      <li class="menu-item" aria-haspopup="true" routerLinkActive="menu-item-active">
        <a class="menu-link" routerLink="/information" routerLinkActive="active" (click)="onClickMenu('information')">
          <i class="material-icons md-22 icon-menu-min">share</i>
          <span class="menu-text">{{'MENU.SHAREINFO' | translate}}</span>
        </a>
      </li>


      <li class="menu-item" aria-haspopup="true" data-menu-toggle="hover" routerLinkActive="menu-item-open">

        <button class="btn btn-primary mt-5 menu-toggle menu-btn" routerLink="/create/company" routerLinkActive="active"
          (click)="onClickMenu('Creación sociedad')"><i class="fas fa-plus md-14"></i> <span
            class="text-btn">{{'MENU.ADD' | translate}}
          </span>
        </button>
      </li>
    </ul>
  </div>
</div>

<div class="brand-bottom flex-column-auto" id="kt_brand">
  <img class="img-logo" alt="Logo" [attr.src]="brevity_logo" />
</div>