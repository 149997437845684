import { Location } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { RestService } from "src/app/core/services/rest.service";
import { AmplitudeService } from "src/app/core/services/amplitude.service";
import { UsersService } from "src/app/core/services/users.services";
import { LayoutService } from "../../layout.service";
import { ExternalAuthService } from "src/app/auth/external-auth.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-aside",
  templateUrl: "./aside.component.html",
  styleUrls: ["./aside.component.scss"],
})
export class AsideComponent implements OnInit, OnDestroy {
  disableAsideSelfDisplay: boolean;
  header_logo: string;
  header_name: string;
  brandSkin: string;
  ulCSSClasses: string;
  location: Location;
  asideMenuHTMLAttributes: any = {};
  asideMenuCSSClasses: string;
  asideMenuDropdown;
  brandClasses: string;
  asideMenuScroll = 1;
  asideSelfMinimizeToggle = false;
  companyId: any;
  brevity_logo: string;
  subs: Subscription;

  constructor(
    private layout: LayoutService,
    private loc: Location,
    public societiesService: RestService,
    public userS: UsersService,
    private _amplitudeService: AmplitudeService,
    private _authService: ExternalAuthService
  ) {}

  ngOnInit(): void {
    // load view settings
    this.disableAsideSelfDisplay =
      this.layout.getProp("aside.self.display") === false;
    this.brandSkin = this.layout.getProp("brand.self.theme");
    this.ulCSSClasses = this.layout.getProp("aside_menu_nav");
    this.asideMenuCSSClasses = this.layout.getStringCSSClasses("aside_menu");
    this.asideMenuHTMLAttributes = this.layout.getHTMLAttributes("aside_menu");
    this.asideMenuDropdown = this.layout.getProp("aside.menu.dropdown")
      ? "1"
      : "0";
    this.brandClasses = this.layout.getProp("brand");
    this.asideSelfMinimizeToggle = this.layout.getProp(
      "aside.self.minimize.toggle"
    );
    this.asideMenuScroll = this.layout.getProp("aside.menu.scroll") ? 1 : 0;
    this.location = this.loc;
    this.brevity_logo = this.getLogoBottom();

    const user = this._authService.getCurrentUser();

    if (user.firm) {
      const firm_id = user.firm.id;
      const firm_name = user.firm.name;
      this.getLogo(firm_id, firm_name);
      this._amplitudeService.setLoggedUser(user);
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private getLogo(firm_id, firm_name) {
    const imgUrl = `./assets/media/logos/` + firm_id + `.png`;

    this.fileExists(imgUrl, (exists) => {
      if (exists) {
        this.header_logo = imgUrl;
      } else {
        this.header_name = firm_name;
      }
    });
  }

  private getLogoBottom() {
    if (this.brandSkin === "light") {
      return "./assets/media/logos/logo-dark.png";
    } else {
      return "./assets/media/logos/logo-light.png";
    }
  }

  fileExists(url, callback) {
    const img = new Image();
    img.src = url;

    if (img.complete) {
      callback(true);
    } else {
      img.onload = () => {
        callback(true);
      };

      img.onerror = () => {
        callback(false);
      };
    }
  }

  onClickMenu(action: string): void {
    this._amplitudeService.setButtonMenuClicked({ action });
  }
}
