import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RestService } from 'src/app/core/services/rest.service';
import { TipoDocumento } from '../../documents/argentina/multitramite/models/documentType.enum';

@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss'],
})
export class InputFileComponent implements OnChanges {
  file_name: string;

  edit = true;
  constructor(private rs: RestService, private snackbar: MatSnackBar) { }

  @Input() label: string;
  @Input() id: string;
  @Input() currentFile?: CurrentFile;
  @Output() emitFile: EventEmitter<File> = new EventEmitter();

  ngOnChanges(): void {
    this.edit = !this.currentFile;
  }

  toggleEdit() {
    this.edit = !this.edit;
  }

  downloadFile() {
    this.rs.setPath('library');
    let newApi = `file/${this.currentFile.guid}`;
    const newFile = `${this.currentFile.name}`;
    const type = this.currentFile.procedure_type_id;

    if (type === TipoDocumento.Accionistas) {
      newApi = `mapa/${this.currentFile.company_id}/${this.currentFile.guid}`;
    }
    else if (type === TipoDocumento.Personas) {
      newApi = `individual/${this.currentFile.individual_id}/${this.currentFile.guid}`; 
    }

    return this.rs
      .downloadFile(newApi, newFile)
      .subscribe(
        (response) => {
          if (response) {
            this.rs.downloadFileError(newApi).subscribe(
              (res) => {
                if (res) {
                  const errorMessage = (res.result) ? (res.result) : (res.message?.user ? res.message?.user : '');
                  this.snackbar.open((errorMessage !== '') ? errorMessage : 'Ha ocurrido un error. Contacte al administrador', 'Cerrar', {
                    duration: 5000,
                  });
                }
              });
          }
        });
  }

  upload_attachment(e) {
    const file: File = e.target.files[0];
    if (file) {
      this.file_name = file.name;
      this.emitFile.emit(file);
    }
  }
}

export class CurrentFile {
  name: string;
  guid: string;
  procedure_type_id?: number;
  company_id?: number;
  individual_id?: number;
}
