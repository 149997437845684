<div class="table-responsive angular-bootstrap-table">
    <div class="table-responsive">
        <table mat-table [dataSource]="dataSource" class="table table-vertical-center
            overflow-hidden table-head-custom">
            <tr mat-header-row *matHeaderRowDef="displayedColumns"> </tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [@fadein] [class.row-inactive]="row.inactive">
            </tr>
            <ng-container *ngFor="let col of columns" [matColumnDef]="col.caption">
                <th mat-header-cell *matHeaderCellDef [ngClass]="col.largeCol ? 'col-md-5' : ''"
                    (click)="clClicked(col.caption, orderDesc)">
                    <ng-container *ngIf="col.type !== 'selection'">
                        <span *ngIf="!col.order">{{col.caption | translate}}</span>
                        <span *ngIf="col.order" class="col-pointer" title="{{'LABELS.CLICKORDER' | translate}}">{{col.caption | translate}}
                            <span style="margin-left: 8px;">
                               <mat-icon *ngIf="columnOrder === col.caption" [ngClass]="orderDesc ? 'fa fa-arrow-down' : 'fa fa-arrow-up'"></mat-icon>
                            </span>
                        </span>
                    </ng-container>
                    <ng-container *ngIf="col.type === 'selection'" class="selection-button">
                        <mat-checkbox (change)="$event ? masterToggle() :
                            null" [checked]="selection.hasValue() && allSelected"
                            [indeterminate]="selection.hasValue() && !allSelected">
                        </mat-checkbox>
                    </ng-container>
                </th>
                <td mat-cell *matCellDef="let element" [ngClass]="largeTable ? 'mat-column' : ''">
                    <ng-container [ngSwitch]="col.type">
                        <span *ngSwitchCase="columnTypes.detailed">
                            <div class="expanded-col">
                                <div *ngIf="expanded !== element.id" [@fadein]>
                                    <span *ngIf="col.field !== 'individuals'">
                                        <span *ngIf="!col.translate">{{element[col.field][0]?.description}}</span>
                                        <span *ngIf="col.translate">{{element[col.field][0]?.description | translate}}</span>
                                    </span>
                                    <span *ngIf="col.field === 'individuals'">
                                        <a class="link" *ngIf="element[col.field][0]?.individual_vinculate_company_id"
                                            [routerLink]="'/company/'+element[col.field][0]?.individual_vinculate_company_id">
                                            {{element[col.field][0]?.description}}
                                        </a>
                                        <a class="link"
                                            *ngIf="!(element[col.field][0]?.individual_vinculate_company_id)"
                                            [routerLink]="'/individuals/individual/'+element[col.field][0]?.id">
                                            {{element[col.field][0]?.description}}
                                        </a>
                                    </span>
                                </div>

                                <div *ngIf="expanded === element.id" [@fadein]>
                                    <div *ngFor="let item of element[col.field]">
                                        <span *ngIf="col.field !== 'individuals'">
                                            <span *ngIf="!col.translate">{{item.description}}</span>
                                            <span *ngIf="col.translate">{{item.description | translate}}</span>
                                        </span>

                                        <span *ngIf="col.field === 'individuals'">
                                            <a class="link" *ngIf="item.individual_vinculate_company_id"
                                                [routerLink]="'/company/'+item.individual_vinculate_company_id">
                                                {{item.description}}
                                            </a>
                                            <a class="link" *ngIf="!(item.individual_vinculate_company_id)"
                                                [routerLink]="'/individuals/individual/'+item.id">
                                                {{item.description}}
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class=" show-more" *ngIf="element[col.field]?.length>1"
                                (click)="showDetail(element.id)">Ver {{expanded === element.id? 'menos' :
                                (element[col.field].length - 1) + ' más'}}
                            </div>
                        </span>

                        <span *ngSwitchCase="columnTypes.checkbox">
                            <input type="checkbox" class="form-check-input form-check-input-fix" mat-checkbox
                                name="stock_paid" id="stock_paid" [checked]="element[col.field]"
                                (click)="actClicked(col.actionType , element)" />
                        </span>

                        <span *ngSwitchCase="columnTypes.checkboxReadonly">
                            <div *ngIf="element[col.field]">
                                <mat-icon style="color: #197ef8;">done</mat-icon>
                            </div>
                        </span>

                        <span *ngSwitchCase="columnTypes.list">
                            <div class="list-col" *ngFor="let item of element[col.field]">{{item.description}}
                            </div>
                        </span>

                        <span *ngSwitchCase="columnTypes.selection">
                            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? handleSelection(element) :
                            null" [checked]="selection.isSelected(element)">
                            </mat-checkbox>
                        </span>
                        <span *ngSwitchCase="columnTypes.date" style="margin-left:5px">
                            {{ element[col.field] | date: 'dd/MM/yyyy'}}
                        </span>

                        <span *ngSwitchCase="columnTypes.percentage" style="margin-left:5px">
                            {{ element[col.field] | customRound:'.10-10'}} %
                        </span>

                        <span *ngSwitchCase="columnTypes.regular" class="d-flex align-items-center">
                            <ng-container *ngIf="col.uppercase">
                                {{ element[col.field] | uppercase }}
                            </ng-container>
                            <ng-container *ngIf="!col.uppercase && col.translate">
                                {{ element[col.field] | translate}}
                            </ng-container>
                            <ng-container *ngIf="!col.uppercase && !col.translate">
                                {{ element[col.field]}}
                            </ng-container>
                        </span>
                        <span *ngSwitchCase="columnTypes.combination" class="d-flex align-items-center">
                            <ng-container>
                                {{ element[col.field] }} {{ element[col.fieldOfCombination] }}
                            </ng-container>
                        </span>
                        <span *ngSwitchCase="columnTypes.link">
                            <div
                                *ngIf="col.fieldOfRedirection === 'individual_id' && element.individual_vinculate_company_id">
                                <a class="link" [routerLink]="'/company/'+element.individual_vinculate_company_id">
                                    {{ element[col.field] }}
                                </a>
                            </div>
                            <div
                                *ngIf="!(col.fieldOfRedirection === 'individual_id' && element.individual_vinculate_company_id)">
                                <a class="link"
                                    [routerLink]="col.linkToRedirectTo + (col.fieldOfRedirection !== '' ? element[col.fieldOfRedirection]: '')">
                                    {{ element[col.field] }}
                                </a>
                            </div>

                        </span>
                        <span *ngSwitchCase="columnTypes.iconLink" class="ml-3">
                            <a class="link"
                                [routerLink]="col.linkToRedirectTo + (col.fieldOfRedirection !== '' ? element[col.fieldOfRedirection]: '')">
                                <mat-icon class="material-icons light-grey">{{col.icon}}</mat-icon>
                                <mat-icon *ngIf="col.svgIcon" [svgIcon]="col.svgIcon"></mat-icon>
                            </a>
                        </span>
                        <span *ngSwitchCase="columnTypes.iconAction" class="ml-3">
                            <a [title]="col.actionTitle | translate" (click)="actClicked(col.actionType , element)">
                                <mat-icon *ngIf="col.icon" [ngStyle]="{'color': col.iconColor}"
                                    class="material-icons light-grey">
                                    {{col.icon}}</mat-icon>
                                <mat-icon *ngIf="col.svgIcon" [svgIcon]="col.svgIcon"></mat-icon>
                            </a>
                        </span>
                        <span *ngSwitchCase="columnTypes.iconMultiAction" class="container-icons">
                            <span *ngFor="let action of col.multiActionIcons">
                                <a *ngIf="action.link && !action.conditional"
                                    [routerLink]="action.link + element[action.fieldOfRedirection]"
                                    [title]="action.actionTitle  | translate"
                                    (click)="actClicked(action.actionType , element)">
                                    <mat-icon *ngIf="action.icon" class="material-icons light-grey">{{action.icon}}
                                    </mat-icon>
                                    <span *ngIf="action.svgPath" [inlineSVG]="col.svgPathOn" cacheSVG="true"></span>
                                    <mat-icon *ngIf="action.svgIcon" [svgIcon]="action.svgIcon"></mat-icon>

                                </a>
                                <a *ngIf="action.linkDynamic"
                                    [routerLink]="element[action.linkDynamic] + element[action.fieldOfRedirection]"
                                    [title]="action.actionTitle  | translate"
                                    (click)="actClicked(action.actionType , element)">
                                    <mat-icon *ngIf="action.icon" class="material-icons light-grey">{{action.icon}}
                                    </mat-icon>
                                    <span *ngIf="action.svgPath" [inlineSVG]="col.svgPathOn" cacheSVG="true"></span>
                                    <mat-icon *ngIf="action.svgIcon" [svgIcon]="action.svgIcon"></mat-icon>

                                </a>
                                <a *ngIf="action.actionType && !action.conditional"
                                    [title]="action.actionTitle  | translate"
                                    (click)="actClicked(action.actionType , element)">
                                    <mat-icon *ngIf="action.icon" class="material-icons light-grey">{{action.icon}}
                                    </mat-icon>
                                    <span *ngIf="action.svgPath" [inlineSVG]="col.svgPathOn" cacheSVG="true"></span>
                                    <mat-icon *ngIf="action.svgIcon" [svgIcon]="action.svgIcon">
                                    </mat-icon>

                                </a>
                                <a *ngIf="action.actionType && action.conditional && element[action.fieldOfCondition]"
                                    [title]="action.actionTitle  | translate"
                                    (click)="actClicked(action.actionType , element)">
                                    <mat-icon *ngIf="action.icon" class="material-icons light-grey">{{action.icon}}
                                    </mat-icon>
                                    <span *ngIf="action.svgPath" [inlineSVG]="col.svgPathOn" cacheSVG="true"></span>
                                    <mat-icon *ngIf="action.svgIcon" [svgIcon]="action.svgIcon"></mat-icon>
                                </a>
                                <a *ngIf="action.link && action.conditional && element[action.fieldOfCondition]"
                                    [routerLink]="element[action.link] + element[action.fieldOfRedirection]"
                                    [title]="action.actionTitle  | translate"
                                    (click)="actClicked(action.actionType , element)">
                                    <mat-icon *ngIf="action.icon" class="material-icons light-grey">{{action.icon}}
                                    </mat-icon>
                                    <span *ngIf="action.svgPath" [inlineSVG]="col.svgPathOn" cacheSVG="true"></span>
                                    <mat-icon *ngIf="action.svgIcon" [svgIcon]="action.svgIcon"></mat-icon>

                                </a>

                                <a *ngIf="action.actionType ==='linkDocs'" [title]="action.actionTitle  | translate"
                                    (click)="actClicked(action.actionType , element)">
                                    <mat-icon style="width: 28px;
                                    padding-bottom: 7px;cursor: pointer;height: 22px;"
                                        [svgIcon]="element[col.field]? 'link-active':'link'"
                                        class="material-icons material-icons icon-size">
                                    </mat-icon>
                                    <mat-icon *ngIf="action.svgIcon" [svgIcon]="action.svgIcon"></mat-icon>

                                </a>
                            </span>
                        </span>
                        <span *ngSwitchCase="columnTypes.regularWMulticolorIcons" class="d-flex align-items-center">
                            <mat-icon *ngIf="element['deletable'] && element['resolved']"
                                class="material-icons icon-size" [ngStyle]="{'color':'green'}" size="large">check
                            </mat-icon>

                            <mat-icon *ngIf="!element['deletable'] || (element['deletable'] && element['notResolved']) "
                                class="material-icons icon-size"
                                [ngStyle]="{'color': col.colors[element[col.colorField]]}" size="large">{{col.icon}}
                            </mat-icon>
                            {{ element[col.field] | translate}}
                        </span>
                        <span *ngSwitchCase="columnTypes.toggleSvgIcon">
                            <button mat-icon-button (click)="actClicked(col.actionType ,element)">
                                <span *ngIf="element[col.field]" [inlineSVG]="col.svgPathOn" cacheSVG="true"></span>
                                <span *ngIf="!element[col.field]" [inlineSVG]="col.svgPathOff" cacheSVG="true"></span>
                            </button>
                        </span>
                    </ng-container>
                </td>
            </ng-container>
        </table>
        <div class="empty-response" [ngStyle]="{height: (pageSize * 3).toString() + 'rem'}"
            *ngIf="dataSource?.length === 0 && !loading">
            <mat-icon class="material-icons icon-size" size="large">search_off</mat-icon>
            {{'TABLES.EMPTY' | translate}}
        </div>

        <div class="empty-response" [ngStyle]="{height: (pageSize * 3).toString() + 'rem' }"
            *ngIf="loading && !dataSource">
            <span class="spinner spinner-primary ml-5"></span>
        </div>

        <ng-content select="[paginator]"></ng-content>
    </div>
</div>