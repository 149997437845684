<button *ngIf="type==='primary'" class="btn bg-primary color-white sm" (click)="emitClick()"   [disabled]="disable">
    {{label | translate }}
</button>

<button *ngIf="type==='primary-md'" class="btn bg-primary color-white md" (click)="emitClick()"   [disabled]="disable">
    {{label | translate}}
</button>

<button *ngIf="type==='primary-lg'" class="btn bg-primary color-white lg" (click)="emitClick()"   [disabled]="disable">
    {{label | translate}}
</button>

<button *ngIf="type==='primary-lg-icon'" class="btn-icon bg-primary color-white lg-icon {{icon}}" (click)="emitClick()"   [disabled]="disable">
    <div *ngIf="iconPosition !== 'right'" class="icon {{icon}}"></div>
    {{label | translate}}
    <div *ngIf="iconPosition === 'right'" class="icon {{icon}}"></div>
</button>

<button *ngIf="type==='secondary'" class="btn bg-light-grey sm" (click)="emitClick()"   [disabled]="disable">
    {{label | translate}}
</button>

<button *ngIf="type==='secondary-md'" class="btn bg-light-grey md" (click)="emitClick()"   [disabled]="disable">
    {{label | translate}}
</button>

<button *ngIf="type==='secondary-lg'" class="btn bg-light-grey lg" (click)="emitClick()"   [disabled]="disable">
    {{label | translate}}
</button>

<button *ngIf="type==='secondary-lg-icon'" class="btn-icon bg-light-grey lg-icon" (click)="emitClick()"   [disabled]="disable">
    <div class="icon {{icon}}"></div>
    {{label | translate}}
</button>

<button *ngIf="type==='delete'" class="btn-icon bg-red delete lg-icon color-white" (click)="emitClick()"   [disabled]="disable">
    <div class="icon trash"></div>
    {{label | translate}}
</button>

<button *ngIf="type==='loading'" class="btn btn bg-primary sm color-white center" [ngClass]="{'btn-icon' : loading}">
    <span *ngIf="!loading" [@fadein]>{{label | translate}}</span>
    <div *ngIf="loading" [@fadein] class="icon loading"></div>
</button>

<button *ngIf="type==='warning'" class="btn warning" (click)="emitClick()"   [disabled]="disable">
    {{label | translate}}
</button>

<button *ngIf="type==='signout'" class="btn warning" (click)="emitClick()"   [disabled]="disable">
    <!--div class="signout icon"></div-->
    {{label | translate}}
</button>