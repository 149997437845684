/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiResult } from 'src/app/core/models/api-result.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RestService {
  // Private fields
  protected _isLoading$ = new BehaviorSubject<boolean>(false);
  protected _errorMessage = new BehaviorSubject<string>('');
  protected _subscriptions: Subscription[] = [];
  protected http: HttpClient;
  // API URL has to be overrided
  API_URL = `${environment.apiUrl}`;

  constructor(http: HttpClient) {
    this.http = http;
  }

  setPath(path: string) {
    this.API_URL = `${environment.apiUrl}${path}`;
  }

  // trasforms Object to fromData
  buildObjectFormData(obj: Object, prefix?: string) {
    let formData = new FormData();

    Object.keys(obj).forEach((key) => {
      formData.append(`${prefix ? prefix : ''}${key}`, obj[key]);
    });

    return formData;
  }

  // CREATE
  // server should return the object with ID
  create(item: any): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.post<ApiResult>(this.API_URL, item).pipe(
      catchError((err) => {
        this._errorMessage.next(err);
        console.error('CREATE ITEM', err);
        return of({ id: undefined });
      }),
      map((response: ApiResult) => {
        return response.result;
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  post(item: any): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http
      .post<string>(`${environment.apiUrlDownloads}bucket`, item)
      .pipe(
        catchError((err) => {
          this._errorMessage.next(err);
          return of({ id: undefined });
        }),
        map((response: string) => {
          return response;
        }),
        finalize(() => this._isLoading$.next(false))
      );
  }

  post_to(item: any, path: string): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.post<ApiResult>(`${environment.apiUrl}${path}`, item).pipe(
      catchError((err) => {
        this._errorMessage.next(err);
        return of({ id: undefined });
      }),
      map((response: ApiResult) => {
        return response.result;
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  post_to_any(item: any, path: string): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.post<any>(`${environment.apiUrl}${path}`, item).pipe(
      catchError((err) => {
        this._errorMessage.next(err);
        return of(err);
      }),
      map((response: any) => {
        return response;
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  delete_from(path: string, id: string): Observable<any> {
    this._errorMessage.next('');
    return this.http
      .delete<ApiResult>(`${environment.apiUrl}${path}/${id}`)
      .pipe(
        catchError((err) => {
          this._errorMessage.next(err);
          return of({ id: undefined });
        })
      );
  }

  get_from(path: string): Observable<any> {
    this._errorMessage.next('');
    return this.http.get<ApiResult>(`${environment.apiUrl}${path}`).pipe(
      map((response: ApiResult) => {
        return response.result ? response.result : response;
      })
    );
  }

  get_api(path): Observable<ApiResult | any> {
    return this.http.get<ApiResult>(`${environment.apiUrl}${path}`);
  }

  get_api_by_params(path, parameters): Observable<ApiResult> {
    let params = new HttpParams();

    Object.keys(parameters).forEach((key) => {
      if (parameters[key] != null) {
        params = params.set(key, `${parameters[key]}`);
      }
    });

    return this.http.get<ApiResult>(`${environment.apiUrl}${path}`, { params });
  }

  put_api(path, data): Observable<ApiResult> {
    return this.http.put<ApiResult>(`${environment.apiUrl}${path}`, data);
  }
  post_api(path, data): Observable<ApiResult> {
    return this.http.post<ApiResult>(`${environment.apiUrl}${path}`, data);
  }
  delete_api(path, body?): Observable<ApiResult> {
    return this.http.delete<ApiResult>(`${environment.apiUrl}${path}`);
  }

  download(url, item: any): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.post<ApiResult>(url, item).pipe(
      catchError((err) => {
        this._errorMessage.next(err);
        console.error('CREATE ITEM', err);
        return of({ id: undefined });
      }),
      map((response: ApiResult) => {
        return response.result;
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  downloadFile(
    path: string,
    filename: string,
    contentType: string = 'application/pdf',
    preview?
  ): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http
      .get(`${this.API_URL}/${path}`, {
        responseType: 'blob',
      })
      .pipe(
        catchError((err) => {
          this._errorMessage.next(err);
          console.error('Download file', err);
          return of({ id: undefined });
        }),
        map((response: any) => {

          const file = new window.Blob([response], {
            type: response?.type ? response?.type : contentType,
          });

          const downloadAncher = document.createElement('a');
          const fileURL = URL.createObjectURL(file);
          downloadAncher.href = fileURL;
          downloadAncher.style.display = 'none';

          if (preview) {
            downloadAncher.setAttribute('target', '_blank');
          } else {
            downloadAncher.download = filename;
          }

          downloadAncher.click();
          downloadAncher.remove();
        }),
        finalize(() => this._isLoading$.next(false))
      );
  }

  downloadFileError(path: string): Observable<any> {
    return this.http
      .get(path, {
        headers: { authorization: `Bearer` },
      })
      .pipe(map((response: any) => response));
  }

  postAndDownloadFile(
    path: string,
    obj: any,
    filename: string,
    contentType: string = 'application/pdf'
  ): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http
      .post(`${environment.apiUrl}${path}`, obj, {
        responseType: 'blob',
      })
      .pipe(
        catchError((err) => {
          this._errorMessage.next(err);
          console.error('Download file', err);
          return of({ id: undefined });
        }),
        map((response: any) => {
          const file = new window.Blob([response], {
            type: response?.type ? response?.type : contentType,
          });

          const downloadAncher = document.createElement('a');
          downloadAncher.style.display = 'none';

          const fileURL = URL.createObjectURL(file);
          downloadAncher.href = fileURL;
          downloadAncher.download = filename;
          downloadAncher.click();
          downloadAncher.remove();
        }),
        finalize(() => this._isLoading$.next(false))
      );
  }

  getTop(count: number): Observable<any> {
    this._errorMessage.next('');
    return this.http.get<ApiResult>(`${this.API_URL}/top/${count}`).pipe(
      map((response: ApiResult) => {
        return response.result;
      })
    );
  }

  getAllWithoutLimit(): Observable<any> {
    this._errorMessage.next('');
    return this.http.get<any>(`${this.API_URL}`).pipe(
      catchError((err) => {
        this._errorMessage.next(err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  getAll(): Observable<any> {
    this._errorMessage.next('');
    return this.http.get<any>(`${this.API_URL}/top/2500`).pipe(
      map((response: ApiResult) => {
        return response.result;
      }),
      catchError((err) => {
        this._errorMessage.next(err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  get(paginated = false): Observable<any> {
    this._errorMessage.next('');
    return this.http.get<any>(`${this.API_URL}`).pipe(
      map((response: ApiResult) => {
        if (paginated) {
          return response;
        }
        return response.result;
      }),
      catchError((err) => {
        this._errorMessage.next(err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  getByParams(parameters: any): Observable<any> {
    this._errorMessage.next('');

    var params = new HttpParams();

    Object.keys(parameters).forEach((key) => {
      params = params.set(key, `${parameters[key]}`);
    });

    return this.http.get<any>(`${this.API_URL}`, { params }).pipe(
      map((response: ApiResult) => {
        return response;
      }),
      catchError((err) => {
        console.error(err);
        this._errorMessage.next(err);
        return of({ result: null, success: false });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  getById(id: number | string): Observable<{}> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const token = sessionStorage.getItem('paramT');

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    const url = `${this.API_URL}/${id}`;
    return this.http
      .get<ApiResult>(url, {
        headers: httpHeaders,
      })
      .pipe(
        catchError((err) => {
          this._errorMessage.next(err);
          console.error('GET ITEM BY ID', id, err);
          return of({ id: undefined });
        }),
        finalize(() => this._isLoading$.next(false))
      );
  }

  update(item: any): Observable<any> {
    const url = `${this.API_URL}`;
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.put(url, item).pipe(
      catchError((err) => {
        this._errorMessage.next(err);
        console.error('UPDATE ITEM', item, err);
        return of(item);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  delete(id: any): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/${id}`;
    return this.http.delete(url).pipe(
      catchError((err) => {
        this._errorMessage.next(err);
        console.error('DELETE ITEM', id, err);
        return of({});
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }
}
