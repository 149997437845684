<form [formGroup]="dataFormGroup" *ngIf="dataFormGroup && IndividualType=== 1">
    <div class="form-group row">
        <div class="col-md-12">
            <label>{{ 'LABELS.NAME' | translate }}</label>
            <input type="text" class="form-control form-control-lg" autocomplete="off" name="name"
                formControlName="name" />
        </div>
    </div>
    <div class="form-group row">
        <div class="col-md-12">
            <label>{{ 'LABELS.COUNTRY' | translate }}</label>
            <mat-select class="form-control" formControlName="countries_id">
                <mat-option *ngFor="let item of countriesList" [value]="item.id">{{item.description}}</mat-option>
            </mat-select>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-md-12">
            <label>{{ 'UBICATION.JURISDICTION' | translate }}</label>
            <input type="text" class="form-control form-control-lg" autocomplete="off" name="jurisdiction"
                formControlName="jurisdiction" />
        </div>
    </div>
    <div class="form-group row">
        <div class="col-md-12">
            <label>{{ 'LABELS.NATIONALITY' | translate }}</label>
            <input type="text" class="form-control form-control-lg" autocomplete="off" name="nationality"
                formControlName="nationality" />
        </div>
    </div>
    <div class="form-group row">
        <div class="col-md-12">
            <label>{{ 'LABELS.LEGAL_REPRESENTATIVE' | translate }}</label>
            <input type="text" class="form-control form-control-lg" autocomplete="off" name="legal_representative"
                formControlName="legal_representative" />
        </div>
    </div>
    <div class="form-group row">
        <div class="col-md-12">
            <label>{{'BY_COUNTRY.'+countryCode+'.INDIVIDUALS.LEGAL_DOCUMENT_TYPE' | translate}}</label>
            <input type="text" class="form-control form-control-lg" autocomplete="off" name="cuit"
                formControlName="cuit" />
        </div>
    </div>
</form>

<form [formGroup]="dataFormGroup" *ngIf="dataFormGroup && IndividualType=== 0">
    <div class="form-group row">
        <div class="col-md-12">
            <label>{{ 'LABELS.NAME' | translate }}</label>
            <input type="text" class="form-control form-control-lg" autocomplete="off" name="name"
                formControlName="name" />
        </div>
    </div>
    <div class="form-group row">
        <div class="col-md-12">
            <label>{{ 'LABELS.LAST_NAME' | translate }}</label>
            <input type="text" class="form-control form-control-lg" autocomplete="off" name="last_name"
                formControlName="last_name" />
        </div>
    </div>
    <div class="form-group row">
        <div class="col-md-12">
            <label>{{ 'LABELS.EMAIL' | translate }}</label>
            <input type="text" class="form-control form-control-lg" autocomplete="off" name="email"
                formControlName="email" />
        </div>
    </div>

    <div class="form-group row">
        <div class="col-md-12">
            <label>{{ 'LABELS.COUNTRY' | translate }}</label>
            <mat-select class="form-control" formControlName="countries_id">
                <mat-option *ngFor="let item of countriesList" [value]="item.id">{{item.description | translate}}</mat-option>
            </mat-select>
        </div>
    </div>

    <div class="form-group row">
        <div class="col-md-12">
            <label>{{ 'LABELS.PHONE' | translate }}</label>
            <input type="text" class="form-control form-control-lg" autocomplete="off" name="phone"
                formControlName="phone" />
        </div>
    </div>
    <div class="form-group row">
        <div class="col-md-12">
            <label>{{ 'LABELS.DOC_TYPE' | translate }}</label>
            <mat-select class="form-control" formControlName="document_type">
                <mat-option *ngFor="let item of documentTypesList" [value]="item">{{item}}</mat-option>
            </mat-select>
        </div>
    </div>

    <div class="form-group row">
        <div class="col-md-12">
            <label>{{ 'LABELS.DOCUMENT' | translate }}</label>
            <input type="text" class="form-control form-control-lg" autocomplete="off" name="document"
                formControlName="document" />
        </div>
    </div>

    <div class="form-group row">
        <div class="col-md-12">
            <label>{{'BY_COUNTRY.'+countryCode+'.INDIVIDUALS.LEGAL_DOCUMENT_TYPE' | translate}}</label>
            <input type="text" class="form-control form-control-lg" autocomplete="off" name="cuit"
                formControlName="cuit" />
        </div>
    </div>

    <div class="form-group row">
        <div class="col-md-12">
            <label>{{ 'LABELS.BIRTH' | translate }}</label>
            <div class="input-group">
                <input type="text" name="birthday" class="form-control disabled" autocomplete="off"
                    formControlName="birthday" [matDatepicker]="picker" (focus)="picker.open()" readonly="true" />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-md-12">
            <label>{{ 'LABELS.OCCUPATION' | translate }}</label>
            <input type="text" class="form-control form-control-lg" autocomplete="off" name="occupation"
                formControlName="occupation" />
        </div>
    </div>
    <div class="form-group row">
        <div class="col-md-12">
            <label>{{ 'LABELS.NATIONALITY' | translate }}</label>
            <input type="text" class="form-control form-control-lg" autocomplete="off" name="nationality"
                formControlName="nationality" />
        </div>
    </div>

    <div class="form-group row">
        <div class="col-md-12">
            <label>{{ 'LABELS.MARITAL_STATUS' | translate }}</label>
            <mat-select class="form-control" formControlName="marital_status">
                <mat-option *ngFor="let item of maritalStatusList" [value]="item">{{item | translate}}</mat-option>
            </mat-select>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-md-12">
            <label>{{ 'LABELS.TITLE' | translate }}</label>
            <mat-select class="form-control" formControlName="individual_prefixes_id">
                <mat-option *ngFor="let item of prefixes" [value]="item.id">{{item.description | translate}}</mat-option>
            </mat-select>
        </div>
    </div>

</form>