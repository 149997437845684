<h1>{{'TITLES.PERSONS' | translate}}</h1>
<br>
<div class="form-group row">
    <div class="col-md-6">
        <div class="form-group row">
            <div class="col-md-6">
                <input type="text" class="form-control" placeholder="{{'PLACEHOLDERS.SEARCH_BY_NAME' | translate}} / {{'LABELS.LEGAL_ENTITY' | translate}}"
                    (keyup)="searchForm($event.target.value , 'name')" />
            </div>
            <div class="col-md-6">
                <input type="text" class="form-control" placeholder="{{'PLACEHOLDERS.SEARCH_BY_LAST_NAME' | translate}}"
                    (keyup)="searchForm($event.target.value , 'lastname')" />
            </div>
        </div>
    </div>


    <div class="col-md-6 text-lg-right">
        <button class="btn btn-secondary font-weight-bold" style="margin-right: 10px;" (click)="downloadFullReport()">
            <div class="button-download">
                <mat-icon>download_file</mat-icon>
                <p> {{'BUTTONS.DOWNLOAD' | translate}} {{'IND.FULL_REPO' | translate}}</p>
            </div>
        </button>

        <a type="button" [routerLink]="'/individuals/profile/add'" class="btn btn-primary font-weight-bold">
            {{'BUTTONS.NUEVA' | translate}} {{'LABELS.PERSON' | translate}}
        </a>
    </div>
</div>

<mat-tab-group class="tabs-container" (selectedTabChange)="tabChange($event)" style="overflow: auto;
max-height: calc(100vh - 225px);">
    <mat-tab label="{{'LABELS.INDIVIDUALS' | translate}}">
        <div class="table-container custom-scrollbar">
            <app-table [dataSource]="individuals" [columns]="columnsPerson" (actionClicked)="handleClick($event)"
                [loading]="isLoading" [pageSize]="size">
                <span paginator>
                    <mat-paginator #MatPaginator1 [length]="total" (page)="changePage($event)"
                        [pageSizeOptions]="[5, 10, 25, 50, 100]">
                    </mat-paginator>
                </span>
            </app-table>
        </div>
    </mat-tab>
    <mat-tab label="{{'LABELS.LEGAL_ENTITIES' | translate}}">
        <div class="table-container custom-scrollbar">
            <app-table [dataSource]="individuals" [columns]="columnsLegals" (actionClicked)="handleClick($event)"
                [loading]="isLoading" [pageSize]="size">
                <span paginator>
                    <mat-paginator #MatPaginator2 (page)="changePage($event)" [length]="total"
                        [pageSizeOptions]="[5, 10, 25, 50, 100]">
                    </mat-paginator>
                </span>
            </app-table>
        </div>
    </mat-tab>
</mat-tab-group>