import { Component,Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface DialogData {
    title: string;
    text: string;
  }

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dialog-confirm',
    templateUrl: 'dialog-confirm.component.html',
  })
  export class DialogConfirmComponent {
    
    constructor(public dialogRef: MatDialogRef<DialogConfirmComponent>,@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

    onNoClick(): void {
        this.dialogRef.close();
      }
  }
