import { TableColumn } from '../../shared/table/table.component';

export const expirationStudioEN = {
  EXPIRATION_NAME: 'Name',
  EXPIRATION_TYPE: 'Expiration type',
  EXPIRATION_DATE: 'Expiration date',
  EXPIRATION_STATUS: 'Expiration status',
  COMPANY: 'Company',
  DELETE: 'Delete',
  EDIT: 'Edit',
  ACTIONS: 'Actions',
  RESOLVED: 'Resolved',
};

export const expirationStudioES = {
  EXPIRATION_NAME: 'Nombre',
  EXPIRATION_TYPE: 'Tipo de vencimiento',
  EXPIRATION_DATE: 'Fecha del vencimiento',
  EXPIRATION_STATUS: 'Estado del vencimiento',
  COMPANY: 'Sociedad',
  DELETE: 'Eliminar',
  EDIT: 'Editar',
  ACTIONS: 'Acciones',
  RESOLVED: 'Resuelto',
};

export const ColumnsExpirations: TableColumn[] = [
  {
    field: 'name',
    caption: 'EXP_STUDIO_TABLE.EXPIRATION_NAME',
    type: 'regular',
  },
  {
    field: 'expiration_date',
    caption: 'EXP_STUDIO_TABLE.EXPIRATION_DATE',
    type: 'date',
  },
  {
    field: 'company',
    caption: 'EXP_STUDIO_TABLE.COMPANY',
    type: 'link',
    linkToRedirectTo: '/company/',
    fieldOfRedirection: 'company_id',
  },
  {
    field: 'expiration_range',
    caption: 'EXP_STUDIO_TABLE.EXPIRATION_STATUS',
    type: 'regular-w-multicolor-icon',
    icon: 'notifications_none',
    colorField: 'expiration_range_id',
    translate: true,
    colors: {
      1: '#FF0000',
      2: 'orange',
      3: 'yellow',
      4: 'green',
    },
  },
  {
    field: 'expiration_type',
    caption: 'EXP_STUDIO_TABLE.EXPIRATION_TYPE',
    type: 'regular',
    translate: true,
  },
  {
    field: 'action',
    type: 'icon-multi-action',
    caption: 'EXP_STUDIO_TABLE.ACTIONS',

    multiActionIcons: [
      {
        actionType: 'resolve',
        icon: 'done',
        actionTitle: 'EXP_STUDIO_TABLE.RESOLVED',
        conditional: true,
        fieldOfCondition: 'resolvable',
      },
      {
        actionType: 'delete',
        icon: 'delete_forever',
        actionTitle: 'EXP_STUDIO_TABLE.DELETE',
        conditional: true,
        fieldOfCondition: 'deletable',
      },
    ],
  },
];

export const expirationByCompanyTable: TableColumn[] = [
  {
    field: 'name',
    caption: 'EXP_STUDIO_TABLE.EXPIRATION_NAME',
    type: 'regular',
  },
  {
    field: 'expiration_date',
    caption: 'EXP_STUDIO_TABLE.EXPIRATION_DATE',
    type: 'date',
  },
  {
    field: 'expiration_type',
    caption: 'EXP_STUDIO_TABLE.EXPIRATION_TYPE',
    type: 'regular',
    translate: true,
  },
  {
    field: 'expiration_range',
    caption: 'EXP_STUDIO_TABLE.EXPIRATION_STATUS',
    type: 'regular-w-multicolor-icon',
    icon: 'notifications_none',
    colorField: 'expiration_range_id',
    translate: true,
    colors: {
      1: '#FF0000',
      2: 'orange',
      3: 'yellow',
      4: 'green',
    },
  },
  {
    field: 'action',
    type: 'icon-multi-action',
    caption: 'EXP_STUDIO_TABLE.ACTIONS',

    multiActionIcons: [
      {
        actionType: 'resolve',
        icon: 'done',
        actionTitle: 'EXP_STUDIO_TABLE.RESOLVED',
        conditional: true,
        fieldOfCondition: 'resolvable',
      },
      {
        actionType: 'delete',
        icon: 'delete_forever',
        actionTitle: 'EXP_STUDIO_TABLE.DELETE',
        conditional: true,
        fieldOfCondition: 'deletable',
      },
    ],
  },
];
