import { ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TableColumn } from 'src/app/modules/shared/table/table.component';
import { authIndvTable, powerIndvTable, shareholderIndvTable, TabsLinkages } from '../../individuals';

@Component({
  selector: 'app-linkages-individual',
  templateUrl: './linkages-individual.component.html',
  styleUrls: ['./linkages-individual.component.scss']
})
export class LinkagesIndividualComponent implements OnChanges {

  @Input() linkages;
  @Input() individual_fullname = '';

  tabsLinkages = TabsLinkages;
  powerTable: TableColumn[] = powerIndvTable;
  shareholderTable: TableColumn[] = shareholderIndvTable;
  authTable: TableColumn[] = authIndvTable;

  constructor(private router: Router, private ref: ChangeDetectorRef) { }

  ngOnChanges(): void {
    if (this.linkages) {
      this.tabsLinkages.forEach(e => {
        if (e.id === 'powers_companies') e.links = this.linkages.powers_companies;
        if (e.id === 'auth_linkages') e.links = this.linkages.authorities_companies;
        if (e.id === 'shaerholder_linkages') e.links = this.linkages.stock_companies;
      });

      this.ref.markForCheck();
    }
  }
  handleClick(e, type) {
    switch (e.action) {
      case 'navigate':
        if (type === 'shareholder' || type === 'directory') {
          this.router.navigateByUrl(`/company/${e?.payload?.company_id}/${type}`);
        }
        else {
          this.router.navigateByUrl(`/upload/edit/${e?.payload?.company_id}/${type}/${e?.payload?.id}`);
        }
        break;
    }
  }
}
